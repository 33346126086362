/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputZoneAdminZone
 */
export interface InputZoneAdminZone {
    /**
     * 
     * @type {number}
     * @memberof InputZoneAdminZone
     */
    zone?: number;
}

/**
 * Check if a given object implements the InputZoneAdminZone interface.
 */
export function instanceOfInputZoneAdminZone(value: object): boolean {
    return true;
}

export function InputZoneAdminZoneFromJSON(json: any): InputZoneAdminZone {
    return InputZoneAdminZoneFromJSONTyped(json, false);
}

export function InputZoneAdminZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputZoneAdminZone {
    if (json == null) {
        return json;
    }
    return {
        
        'zone': json['zone'] == null ? undefined : json['zone'],
    };
}

export function InputZoneAdminZoneToJSON(value?: InputZoneAdminZone | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'zone': value['zone'],
    };
}

