/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputFlightStatus } from './OutputFlightStatus';
import {
    OutputFlightStatusFromJSON,
    OutputFlightStatusFromJSONTyped,
    OutputFlightStatusToJSON,
} from './OutputFlightStatus';
import type { OutputFrequencyStatus } from './OutputFrequencyStatus';
import {
    OutputFrequencyStatusFromJSON,
    OutputFrequencyStatusFromJSONTyped,
    OutputFrequencyStatusToJSON,
} from './OutputFrequencyStatus';
import type { OutputQueueInfo } from './OutputQueueInfo';
import {
    OutputQueueInfoFromJSON,
    OutputQueueInfoFromJSONTyped,
    OutputQueueInfoToJSON,
} from './OutputQueueInfo';
import type { OutputSessionData } from './OutputSessionData';
import {
    OutputSessionDataFromJSON,
    OutputSessionDataFromJSONTyped,
    OutputSessionDataToJSON,
} from './OutputSessionData';

/**
 * 
 * @export
 * @interface OutputZoneChannelData
 */
export interface OutputZoneChannelData {
    /**
     * 
     * @type {Array<number>}
     * @memberof OutputZoneChannelData
     */
    busyFreqs: Array<number>;
    /**
     * 
     * @type {Array<OutputFlightStatus>}
     * @memberof OutputZoneChannelData
     */
    flightStatus: Array<OutputFlightStatus>;
    /**
     * 
     * @type {{ [key: string]: OutputFrequencyStatus; }}
     * @memberof OutputZoneChannelData
     */
    freqsStatuses: { [key: string]: OutputFrequencyStatus; };
    /**
     * 
     * @type {Array<OutputQueueInfo>}
     * @memberof OutputZoneChannelData
     */
    queue: Array<OutputQueueInfo>;
    /**
     * 
     * @type {OutputSessionData}
     * @memberof OutputZoneChannelData
     */
    session: OutputSessionData;
}

/**
 * Check if a given object implements the OutputZoneChannelData interface.
 */
export function instanceOfOutputZoneChannelData(value: object): boolean {
    if (!('busyFreqs' in value)) return false;
    if (!('flightStatus' in value)) return false;
    if (!('freqsStatuses' in value)) return false;
    if (!('queue' in value)) return false;
    if (!('session' in value)) return false;
    return true;
}

export function OutputZoneChannelDataFromJSON(json: any): OutputZoneChannelData {
    return OutputZoneChannelDataFromJSONTyped(json, false);
}

export function OutputZoneChannelDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputZoneChannelData {
    if (json == null) {
        return json;
    }
    return {
        
        'busyFreqs': json['busyFreqs'],
        'flightStatus': ((json['flightStatus'] as Array<any>).map(OutputFlightStatusFromJSON)),
        'freqsStatuses': (mapValues(json['freqsStatuses'], OutputFrequencyStatusFromJSON)),
        'queue': ((json['queue'] as Array<any>).map(OutputQueueInfoFromJSON)),
        'session': OutputSessionDataFromJSON(json['session']),
    };
}

export function OutputZoneChannelDataToJSON(value?: OutputZoneChannelData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'busyFreqs': value['busyFreqs'],
        'flightStatus': ((value['flightStatus'] as Array<any>).map(OutputFlightStatusToJSON)),
        'freqsStatuses': (mapValues(value['freqsStatuses'], OutputFrequencyStatusToJSON)),
        'queue': ((value['queue'] as Array<any>).map(OutputQueueInfoToJSON)),
        'session': OutputSessionDataToJSON(value['session']),
    };
}

