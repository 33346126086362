/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputUserProfile
 */
export interface InputUserProfile {
    /**
     * 
     * @type {boolean}
     * @memberof InputUserProfile
     */
    darkTheme?: boolean;
}

/**
 * Check if a given object implements the InputUserProfile interface.
 */
export function instanceOfInputUserProfile(value: object): boolean {
    return true;
}

export function InputUserProfileFromJSON(json: any): InputUserProfile {
    return InputUserProfileFromJSONTyped(json, false);
}

export function InputUserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputUserProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'darkTheme': json['darkTheme'] == null ? undefined : json['darkTheme'],
    };
}

export function InputUserProfileToJSON(value?: InputUserProfile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'darkTheme': value['darkTheme'],
    };
}

