/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputHistoryEvent
 */
export interface OutputHistoryEvent {
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    repeaterChannel: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    ammo: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    channel: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    channel2?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    division: string;
    /**
     * 
     * @type {number}
     * @memberof OutputHistoryEvent
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof OutputHistoryEvent
     */
    freq: number;
    /**
     * 
     * @type {number}
     * @memberof OutputHistoryEvent
     */
    freq2?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputHistoryEvent
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    pilot: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    target: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    targetStatus: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    team: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    timeEnd: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    timeStart: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    video: string;
    /**
     * 
     * @type {boolean}
     * @memberof OutputHistoryEvent
     */
    withRepeater: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEvent
     */
    zone: string;
}

/**
 * Check if a given object implements the OutputHistoryEvent interface.
 */
export function instanceOfOutputHistoryEvent(value: object): boolean {
    if (!('repeaterChannel' in value)) return false;
    if (!('ammo' in value)) return false;
    if (!('channel' in value)) return false;
    if (!('division' in value)) return false;
    if (!('duration' in value)) return false;
    if (!('freq' in value)) return false;
    if (!('id' in value)) return false;
    if (!('pilot' in value)) return false;
    if (!('target' in value)) return false;
    if (!('targetStatus' in value)) return false;
    if (!('team' in value)) return false;
    if (!('timeEnd' in value)) return false;
    if (!('timeStart' in value)) return false;
    if (!('video' in value)) return false;
    if (!('withRepeater' in value)) return false;
    if (!('zone' in value)) return false;
    return true;
}

export function OutputHistoryEventFromJSON(json: any): OutputHistoryEvent {
    return OutputHistoryEventFromJSONTyped(json, false);
}

export function OutputHistoryEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputHistoryEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'repeaterChannel': json['RepeaterChannel'],
        'ammo': json['ammo'],
        'channel': json['channel'],
        'channel2': json['channel2'] == null ? undefined : json['channel2'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'division': json['division'],
        'duration': json['duration'],
        'freq': json['freq'],
        'freq2': json['freq2'] == null ? undefined : json['freq2'],
        'id': json['id'],
        'pilot': json['pilot'],
        'target': json['target'],
        'targetStatus': json['target_status'],
        'team': json['team'],
        'timeEnd': json['timeEnd'],
        'timeStart': json['timeStart'],
        'video': json['video'],
        'withRepeater': json['withRepeater'],
        'zone': json['zone'],
    };
}

export function OutputHistoryEventToJSON(value?: OutputHistoryEvent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'RepeaterChannel': value['repeaterChannel'],
        'ammo': value['ammo'],
        'channel': value['channel'],
        'channel2': value['channel2'],
        'comment': value['comment'],
        'division': value['division'],
        'duration': value['duration'],
        'freq': value['freq'],
        'freq2': value['freq2'],
        'id': value['id'],
        'pilot': value['pilot'],
        'target': value['target'],
        'target_status': value['targetStatus'],
        'team': value['team'],
        'timeEnd': value['timeEnd'],
        'timeStart': value['timeStart'],
        'video': value['video'],
        'withRepeater': value['withRepeater'],
        'zone': value['zone'],
    };
}

