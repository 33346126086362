/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputAnalyticsOnlineUsers
 */
export interface OutputAnalyticsOnlineUsers {
    /**
     * 
     * @type {number}
     * @memberof OutputAnalyticsOnlineUsers
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof OutputAnalyticsOnlineUsers
     */
    time: string;
}

/**
 * Check if a given object implements the OutputAnalyticsOnlineUsers interface.
 */
export function instanceOfOutputAnalyticsOnlineUsers(value: object): boolean {
    if (!('number' in value)) return false;
    if (!('time' in value)) return false;
    return true;
}

export function OutputAnalyticsOnlineUsersFromJSON(json: any): OutputAnalyticsOnlineUsers {
    return OutputAnalyticsOnlineUsersFromJSONTyped(json, false);
}

export function OutputAnalyticsOnlineUsersFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputAnalyticsOnlineUsers {
    if (json == null) {
        return json;
    }
    return {
        
        'number': json['number'],
        'time': json['time'],
    };
}

export function OutputAnalyticsOnlineUsersToJSON(value?: OutputAnalyticsOnlineUsers | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'number': value['number'],
        'time': value['time'],
    };
}

