/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputDivision
 */
export interface OutputDivision {
    /**
     * 
     * @type {number}
     * @memberof OutputDivision
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDivision
     */
    name: string;
}

/**
 * Check if a given object implements the OutputDivision interface.
 */
export function instanceOfOutputDivision(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function OutputDivisionFromJSON(json: any): OutputDivision {
    return OutputDivisionFromJSONTyped(json, false);
}

export function OutputDivisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputDivision {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function OutputDivisionToJSON(value?: OutputDivision | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

