/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputUserIdentityImpersonatedBy } from './OutputUserIdentityImpersonatedBy';
import {
    OutputUserIdentityImpersonatedByFromJSON,
    OutputUserIdentityImpersonatedByFromJSONTyped,
    OutputUserIdentityImpersonatedByToJSON,
} from './OutputUserIdentityImpersonatedBy';

/**
 * 
 * @export
 * @interface OutputUserIdentity
 */
export interface OutputUserIdentity {
    /**
     * 
     * @type {number}
     * @memberof OutputUserIdentity
     */
    division?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputUserIdentity
     */
    divisionName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputUserIdentity
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof OutputUserIdentity
     */
    id: number;
    /**
     * 
     * @type {OutputUserIdentityImpersonatedBy}
     * @memberof OutputUserIdentity
     */
    impersonatedBy?: OutputUserIdentityImpersonatedBy;
    /**
     * 
     * @type {string}
     * @memberof OutputUserIdentity
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof OutputUserIdentity
     */
    otg?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputUserIdentity
     */
    otgName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputUserIdentity
     */
    role: string;
    /**
     * 
     * @type {number}
     * @memberof OutputUserIdentity
     */
    zone?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputUserIdentity
     */
    zoneName?: string;
}

/**
 * Check if a given object implements the OutputUserIdentity interface.
 */
export function instanceOfOutputUserIdentity(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('role' in value)) return false;
    return true;
}

export function OutputUserIdentityFromJSON(json: any): OutputUserIdentity {
    return OutputUserIdentityFromJSONTyped(json, false);
}

export function OutputUserIdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputUserIdentity {
    if (json == null) {
        return json;
    }
    return {
        
        'division': json['division'] == null ? undefined : json['division'],
        'divisionName': json['divisionName'] == null ? undefined : json['divisionName'],
        'email': json['email'],
        'id': json['id'],
        'impersonatedBy': json['impersonatedBy'] == null ? undefined : OutputUserIdentityImpersonatedByFromJSON(json['impersonatedBy']),
        'name': json['name'],
        'otg': json['otg'] == null ? undefined : json['otg'],
        'otgName': json['otgName'] == null ? undefined : json['otgName'],
        'role': json['role'],
        'zone': json['zone'] == null ? undefined : json['zone'],
        'zoneName': json['zoneName'] == null ? undefined : json['zoneName'],
    };
}

export function OutputUserIdentityToJSON(value?: OutputUserIdentity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'division': value['division'],
        'divisionName': value['divisionName'],
        'email': value['email'],
        'id': value['id'],
        'impersonatedBy': OutputUserIdentityImpersonatedByToJSON(value['impersonatedBy']),
        'name': value['name'],
        'otg': value['otg'],
        'otgName': value['otgName'],
        'role': value['role'],
        'zone': value['zone'],
        'zoneName': value['zoneName'],
    };
}

