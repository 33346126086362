/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputUserProfile
 */
export interface OutputUserProfile {
    /**
     * 
     * @type {boolean}
     * @memberof OutputUserProfile
     */
    darkTheme: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputUserProfile
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof OutputUserProfile
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OutputUserProfile
     */
    name: string;
}

/**
 * Check if a given object implements the OutputUserProfile interface.
 */
export function instanceOfOutputUserProfile(value: object): boolean {
    if (!('darkTheme' in value)) return false;
    if (!('email' in value)) return false;
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function OutputUserProfileFromJSON(json: any): OutputUserProfile {
    return OutputUserProfileFromJSONTyped(json, false);
}

export function OutputUserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputUserProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'darkTheme': json['darkTheme'],
        'email': json['email'],
        'id': json['id'],
        'name': json['name'],
    };
}

export function OutputUserProfileToJSON(value?: OutputUserProfile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'darkTheme': value['darkTheme'],
        'email': value['email'],
        'id': value['id'],
        'name': value['name'],
    };
}

