/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputSessionDivision } from './OutputSessionDivision';
import {
    OutputSessionDivisionFromJSON,
    OutputSessionDivisionFromJSONTyped,
    OutputSessionDivisionToJSON,
} from './OutputSessionDivision';

/**
 * 
 * @export
 * @interface OutputSession
 */
export interface OutputSession {
    /**
     * 
     * @type {OutputSessionDivision}
     * @memberof OutputSession
     */
    division: OutputSessionDivision;
    /**
     * 
     * @type {string}
     * @memberof OutputSession
     */
    expiresAt: string;
    /**
     * 
     * @type {number}
     * @memberof OutputSession
     */
    id: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OutputSession
     */
    pilots: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OutputSession
     */
    startsAt: string;
    /**
     * 
     * @type {string}
     * @memberof OutputSession
     */
    status: string;
    /**
     * 
     * @type {OutputSessionDivision}
     * @memberof OutputSession
     */
    team: OutputSessionDivision;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OutputSession
     */
    zones: { [key: string]: string; };
}

/**
 * Check if a given object implements the OutputSession interface.
 */
export function instanceOfOutputSession(value: object): boolean {
    if (!('division' in value)) return false;
    if (!('expiresAt' in value)) return false;
    if (!('id' in value)) return false;
    if (!('pilots' in value)) return false;
    if (!('startsAt' in value)) return false;
    if (!('status' in value)) return false;
    if (!('team' in value)) return false;
    if (!('zones' in value)) return false;
    return true;
}

export function OutputSessionFromJSON(json: any): OutputSession {
    return OutputSessionFromJSONTyped(json, false);
}

export function OutputSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputSession {
    if (json == null) {
        return json;
    }
    return {
        
        'division': OutputSessionDivisionFromJSON(json['division']),
        'expiresAt': json['expiresAt'],
        'id': json['id'],
        'pilots': json['pilots'],
        'startsAt': json['startsAt'],
        'status': json['status'],
        'team': OutputSessionDivisionFromJSON(json['team']),
        'zones': json['zones'],
    };
}

export function OutputSessionToJSON(value?: OutputSession | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'division': OutputSessionDivisionToJSON(value['division']),
        'expiresAt': value['expiresAt'],
        'id': value['id'],
        'pilots': value['pilots'],
        'startsAt': value['startsAt'],
        'status': value['status'],
        'team': OutputSessionDivisionToJSON(value['team']),
        'zones': value['zones'],
    };
}

