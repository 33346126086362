export const useApp = () => {

    const {isAuthLoaded} = useAuth()

    const isAppLoaded: ComputedRef<boolean> = computed(() => {
        return isAuthLoaded.value
    })

    return {
        isAppLoaded,
    }
}