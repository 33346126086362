/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputChannelsMap
 */
export interface OutputChannelsMap {
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputChannelsMap
     */
    bands: Array<string>;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof OutputChannelsMap
     */
    channelsMap: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof OutputChannelsMap
     */
    freqRanges: { [key: string]: Array<string>; };
}

/**
 * Check if a given object implements the OutputChannelsMap interface.
 */
export function instanceOfOutputChannelsMap(value: object): boolean {
    if (!('bands' in value)) return false;
    if (!('channelsMap' in value)) return false;
    if (!('freqRanges' in value)) return false;
    return true;
}

export function OutputChannelsMapFromJSON(json: any): OutputChannelsMap {
    return OutputChannelsMapFromJSONTyped(json, false);
}

export function OutputChannelsMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputChannelsMap {
    if (json == null) {
        return json;
    }
    return {
        
        'bands': json['bands'],
        'channelsMap': json['channelsMap'],
        'freqRanges': json['freqRanges'],
    };
}

export function OutputChannelsMapToJSON(value?: OutputChannelsMap | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bands': value['bands'],
        'channelsMap': value['channelsMap'],
        'freqRanges': value['freqRanges'],
    };
}

