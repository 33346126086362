import type {Ref} from "vue"
import type {InputAuthToken, OutputUserIdentity} from "~/models";
import {useApi} from "./useApi"
import * as Sentry from "@sentry/browser";

export const useAuth = () => {
    const api = useApi()
    const authState: Ref<OutputUserIdentity|null> = useState("auth")
    const isAuthLoaded: Ref<boolean> = useState('isAuthLoaded', () => false)
    const isAuthenticated: Ref<boolean> = useState('isAuthenticated', () => false)
    const currentRoutePath = useState('currentRoutePath', () => ('/'))

    const getSession = async () => {
        const userIdentity = await api.getAuthSession()
            .catch(ctx => {
                if (ctx.response.status === 401) {
                    return null
                }
            })

        if (userIdentity) {
            authState.value = userIdentity
            isAuthenticated.value = true

            // Set users identity info
            Sentry.setUser(userIdentity)
        } else {
            isAuthenticated.value = false
            authState.value = null
        }

        isAuthLoaded.value = true
    }

    const signIn = async (credentials: InputAuthToken) => {
        await api.getAuthToken({request: credentials})

        await nextTick(getSession)
    }

    const signOut = async () => {
        await api.logoutAuth()
        isAuthenticated.value = false

        Sentry.setUser(null)
    }

    const checkNavigation = (path: string) => {

        const authPage = '/'
        const mainPage = '/fly'

        if (!isAuthLoaded.value) {
            return
        }

        if (!isAuthenticated.value && path != '/') {
            navigateTo(authPage)
            return
        }

        if (isAuthenticated.value && path === '/') {
            navigateTo(mainPage)
            return
        }

        // if (isAuthenticated.value && authState.value && authState.value.role !== roles.ADMIN.value &&
        //     path.match(/^\/admin\//)) {
        //     navigateTo(mainPage)
        //     return
        // }

        // @todo Напевно краще по стріму передавати що у користувача закінчилась сесія, а не ддосити постійно бек
        if (isAuthenticated.value) {
            getSession()
        }
    }

    callOnce(() => {

        // Перевірка авторизації, одразу під час завантаження застосунку
        getSession()

        watch(currentRoutePath, (path) => {
            checkNavigation(path)
        })

        watch(isAuthLoaded, () => {
            checkNavigation(currentRoutePath.value)
        })
        watch(isAuthenticated, () => {
            checkNavigation(currentRoutePath.value)
        })

        setInterval(() => {
            if (isAuthenticated.value) {
                getSession()
            }
        }, 1000 * 60)
    })

    return {
        signIn,
        getSession,
        isAuthenticated,
        isAuthLoaded,
        signOut,
        user: authState as Ref<OutputUserIdentity>,
        currentRoutePath
    }
}