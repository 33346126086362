/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputTargetSelectData
 */
export interface OutputTargetSelectData {
    /**
     * 
     * @type {string}
     * @memberof OutputTargetSelectData
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputTargetSelectData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputTargetSelectData
     */
    title?: string;
}

/**
 * Check if a given object implements the OutputTargetSelectData interface.
 */
export function instanceOfOutputTargetSelectData(value: object): boolean {
    return true;
}

export function OutputTargetSelectDataFromJSON(json: any): OutputTargetSelectData {
    return OutputTargetSelectDataFromJSONTyped(json, false);
}

export function OutputTargetSelectDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputTargetSelectData {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'] == null ? undefined : json['description'],
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'] == null ? undefined : json['title'],
    };
}

export function OutputTargetSelectDataToJSON(value?: OutputTargetSelectData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'id': value['id'],
        'title': value['title'],
    };
}

