/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputLastFlightData } from './OutputLastFlightData';
import {
    OutputLastFlightDataFromJSON,
    OutputLastFlightDataFromJSONTyped,
    OutputLastFlightDataToJSON,
} from './OutputLastFlightData';
import type { OutputMissionData } from './OutputMissionData';
import {
    OutputMissionDataFromJSON,
    OutputMissionDataFromJSONTyped,
    OutputMissionDataToJSON,
} from './OutputMissionData';
import type { OutputSessionData } from './OutputSessionData';
import {
    OutputSessionDataFromJSON,
    OutputSessionDataFromJSONTyped,
    OutputSessionDataToJSON,
} from './OutputSessionData';

/**
 * 
 * @export
 * @interface OutputBookingData
 */
export interface OutputBookingData {
    /**
     * 
     * @type {OutputLastFlightData}
     * @memberof OutputBookingData
     */
    lastFlight: OutputLastFlightData;
    /**
     * 
     * @type {OutputMissionData}
     * @memberof OutputBookingData
     */
    mission: OutputMissionData;
    /**
     * 
     * @type {OutputSessionData}
     * @memberof OutputBookingData
     */
    session: OutputSessionData;
}

/**
 * Check if a given object implements the OutputBookingData interface.
 */
export function instanceOfOutputBookingData(value: object): boolean {
    if (!('lastFlight' in value)) return false;
    if (!('mission' in value)) return false;
    if (!('session' in value)) return false;
    return true;
}

export function OutputBookingDataFromJSON(json: any): OutputBookingData {
    return OutputBookingDataFromJSONTyped(json, false);
}

export function OutputBookingDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputBookingData {
    if (json == null) {
        return json;
    }
    return {
        
        'lastFlight': OutputLastFlightDataFromJSON(json['lastFlight']),
        'mission': OutputMissionDataFromJSON(json['mission']),
        'session': OutputSessionDataFromJSON(json['session']),
    };
}

export function OutputBookingDataToJSON(value?: OutputBookingData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lastFlight': OutputLastFlightDataToJSON(value['lastFlight']),
        'mission': OutputMissionDataToJSON(value['mission']),
        'session': OutputSessionDataToJSON(value['session']),
    };
}

