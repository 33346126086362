/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputMissionData
 */
export interface OutputMissionData {
    /**
     * 
     * @type {string}
     * @memberof OutputMissionData
     */
    activeAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMissionData
     */
    addedExtraTime: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputMissionData
     */
    band: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMissionData
     */
    band2?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMissionData
     */
    channel: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMissionData
     */
    channel2?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMissionData
     */
    freq: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMissionData
     */
    freq2?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputMissionData
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OutputMissionData
     */
    pilotName: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMissionData
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMissionData
     */
    teamName: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMissionData
     */
    timeoutAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof OutputMissionData
     */
    withRepeater: boolean;
    /**
     * 
     * @type {number}
     * @memberof OutputMissionData
     */
    zone: number;
}

/**
 * Check if a given object implements the OutputMissionData interface.
 */
export function instanceOfOutputMissionData(value: object): boolean {
    if (!('activeAt' in value)) return false;
    if (!('addedExtraTime' in value)) return false;
    if (!('band' in value)) return false;
    if (!('channel' in value)) return false;
    if (!('freq' in value)) return false;
    if (!('id' in value)) return false;
    if (!('pilotName' in value)) return false;
    if (!('status' in value)) return false;
    if (!('teamName' in value)) return false;
    if (!('timeoutAt' in value)) return false;
    if (!('withRepeater' in value)) return false;
    if (!('zone' in value)) return false;
    return true;
}

export function OutputMissionDataFromJSON(json: any): OutputMissionData {
    return OutputMissionDataFromJSONTyped(json, false);
}

export function OutputMissionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputMissionData {
    if (json == null) {
        return json;
    }
    return {
        
        'activeAt': json['activeAt'],
        'addedExtraTime': json['addedExtraTime'],
        'band': json['band'],
        'band2': json['band2'] == null ? undefined : json['band2'],
        'channel': json['channel'],
        'channel2': json['channel2'] == null ? undefined : json['channel2'],
        'freq': json['freq'],
        'freq2': json['freq2'] == null ? undefined : json['freq2'],
        'id': json['id'],
        'pilotName': json['pilotName'],
        'status': json['status'],
        'teamName': json['teamName'],
        'timeoutAt': json['timeoutAt'],
        'withRepeater': json['with_repeater'],
        'zone': json['zone'],
    };
}

export function OutputMissionDataToJSON(value?: OutputMissionData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'activeAt': value['activeAt'],
        'addedExtraTime': value['addedExtraTime'],
        'band': value['band'],
        'band2': value['band2'],
        'channel': value['channel'],
        'channel2': value['channel2'],
        'freq': value['freq'],
        'freq2': value['freq2'],
        'id': value['id'],
        'pilotName': value['pilotName'],
        'status': value['status'],
        'teamName': value['teamName'],
        'timeoutAt': value['timeoutAt'],
        'with_repeater': value['withRepeater'],
        'zone': value['zone'],
    };
}

