/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputAuthToken
 */
export interface InputAuthToken {
    /**
     * 
     * @type {number}
     * @memberof InputAuthToken
     */
    actAs?: number;
    /**
     * 
     * @type {string}
     * @memberof InputAuthToken
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof InputAuthToken
     */
    grantType: string;
    /**
     * 
     * @type {string}
     * @memberof InputAuthToken
     */
    source?: string;
}

/**
 * Check if a given object implements the InputAuthToken interface.
 */
export function instanceOfInputAuthToken(value: object): boolean {
    if (!('grantType' in value)) return false;
    return true;
}

export function InputAuthTokenFromJSON(json: any): InputAuthToken {
    return InputAuthTokenFromJSONTyped(json, false);
}

export function InputAuthTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputAuthToken {
    if (json == null) {
        return json;
    }
    return {
        
        'actAs': json['actAs'] == null ? undefined : json['actAs'],
        'code': json['code'] == null ? undefined : json['code'],
        'grantType': json['grantType'],
        'source': json['source'] == null ? undefined : json['source'],
    };
}

export function InputAuthTokenToJSON(value?: InputAuthToken | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'actAs': value['actAs'],
        'code': value['code'],
        'grantType': value['grantType'],
        'source': value['source'],
    };
}

