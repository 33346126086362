/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputHistoryEditableData
 */
export interface OutputHistoryEditableData {
    /**
     * 
     * @type {number}
     * @memberof OutputHistoryEditableData
     */
    ammo: number;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEditableData
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEditableData
     */
    customTarget: string;
    /**
     * 
     * @type {number}
     * @memberof OutputHistoryEditableData
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof OutputHistoryEditableData
     */
    target: number;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEditableData
     */
    targetStatus: string;
    /**
     * 
     * @type {string}
     * @memberof OutputHistoryEditableData
     */
    video: string;
}

/**
 * Check if a given object implements the OutputHistoryEditableData interface.
 */
export function instanceOfOutputHistoryEditableData(value: object): boolean {
    if (!('ammo' in value)) return false;
    if (!('comment' in value)) return false;
    if (!('customTarget' in value)) return false;
    if (!('id' in value)) return false;
    if (!('target' in value)) return false;
    if (!('targetStatus' in value)) return false;
    if (!('video' in value)) return false;
    return true;
}

export function OutputHistoryEditableDataFromJSON(json: any): OutputHistoryEditableData {
    return OutputHistoryEditableDataFromJSONTyped(json, false);
}

export function OutputHistoryEditableDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputHistoryEditableData {
    if (json == null) {
        return json;
    }
    return {
        
        'ammo': json['ammo'],
        'comment': json['comment'],
        'customTarget': json['custom_target'],
        'id': json['id'],
        'target': json['target'],
        'targetStatus': json['target_status'],
        'video': json['video'],
    };
}

export function OutputHistoryEditableDataToJSON(value?: OutputHistoryEditableData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ammo': value['ammo'],
        'comment': value['comment'],
        'custom_target': value['customTarget'],
        'id': value['id'],
        'target': value['target'],
        'target_status': value['targetStatus'],
        'video': value['video'],
    };
}

