/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputAdminAmmo
 */
export interface OutputAdminAmmo {
    /**
     * 
     * @type {string}
     * @memberof OutputAdminAmmo
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputAdminAmmo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminAmmo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminAmmo
     */
    video?: string;
}

/**
 * Check if a given object implements the OutputAdminAmmo interface.
 */
export function instanceOfOutputAdminAmmo(value: object): boolean {
    return true;
}

export function OutputAdminAmmoFromJSON(json: any): OutputAdminAmmo {
    return OutputAdminAmmoFromJSONTyped(json, false);
}

export function OutputAdminAmmoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputAdminAmmo {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'] == null ? undefined : json['description'],
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'video': json['video'] == null ? undefined : json['video'],
    };
}

export function OutputAdminAmmoToJSON(value?: OutputAdminAmmo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'id': value['id'],
        'name': value['name'],
        'video': value['video'],
    };
}

