/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputTargetType } from './OutputTargetType';
import {
    OutputTargetTypeFromJSON,
    OutputTargetTypeFromJSONTyped,
    OutputTargetTypeToJSON,
} from './OutputTargetType';

/**
 * 
 * @export
 * @interface OutputTargetTypesResult
 */
export interface OutputTargetTypesResult {
    /**
     * 
     * @type {Array<OutputTargetType>}
     * @memberof OutputTargetTypesResult
     */
    items: Array<OutputTargetType>;
}

/**
 * Check if a given object implements the OutputTargetTypesResult interface.
 */
export function instanceOfOutputTargetTypesResult(value: object): boolean {
    if (!('items' in value)) return false;
    return true;
}

export function OutputTargetTypesResultFromJSON(json: any): OutputTargetTypesResult {
    return OutputTargetTypesResultFromJSONTyped(json, false);
}

export function OutputTargetTypesResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputTargetTypesResult {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(OutputTargetTypeFromJSON)),
    };
}

export function OutputTargetTypesResultToJSON(value?: OutputTargetTypesResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(OutputTargetTypeToJSON)),
    };
}

