/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputZoneAdminDivision
 */
export interface OutputZoneAdminDivision {
    /**
     * 
     * @type {number}
     * @memberof OutputZoneAdminDivision
     */
    divisionId?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputZoneAdminDivision
     */
    divisionName?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputZoneAdminDivision
     */
    zoneId?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputZoneAdminDivision
     */
    zoneName?: string;
}

/**
 * Check if a given object implements the OutputZoneAdminDivision interface.
 */
export function instanceOfOutputZoneAdminDivision(value: object): boolean {
    return true;
}

export function OutputZoneAdminDivisionFromJSON(json: any): OutputZoneAdminDivision {
    return OutputZoneAdminDivisionFromJSONTyped(json, false);
}

export function OutputZoneAdminDivisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputZoneAdminDivision {
    if (json == null) {
        return json;
    }
    return {
        
        'divisionId': json['divisionId'] == null ? undefined : json['divisionId'],
        'divisionName': json['divisionName'] == null ? undefined : json['divisionName'],
        'zoneId': json['zoneId'] == null ? undefined : json['zoneId'],
        'zoneName': json['zoneName'] == null ? undefined : json['zoneName'],
    };
}

export function OutputZoneAdminDivisionToJSON(value?: OutputZoneAdminDivision | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'divisionId': value['divisionId'],
        'divisionName': value['divisionName'],
        'zoneId': value['zoneId'],
        'zoneName': value['zoneName'],
    };
}

