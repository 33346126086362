import {Configuration, DefaultApi, type Middleware} from "~/models";
const doNotTrackErrorsON = [
    '/api/auth/session',
]

export const useApi = () => {
    const {showApiError} = useApiErrors()
    const {start, finish} = useLoadingIndicator()

    return new DefaultApi(new Configuration({
        basePath: '',
        middleware: [
            {
                pre: (context) => {
                    start()
                },
            } as Middleware,
            {
                post: (context) => {
                    finish()

                    if (context.response.ok) {
                        return
                    }

                    if (!doNotTrackErrorsON.includes(context.url)) {
                        showApiError(context.response.status, context.response)
                    }
                },
            } as Middleware
        ]
    }))
}