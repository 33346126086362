/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputFlightStatus
 */
export interface OutputFlightStatus {
    /**
     * 
     * @type {string}
     * @memberof OutputFlightStatus
     */
    band: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFlightStatus
     */
    band2?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFlightStatus
     */
    channel: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFlightStatus
     */
    channel2?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputFlightStatus
     */
    freq: number;
    /**
     * 
     * @type {number}
     * @memberof OutputFlightStatus
     */
    freq2?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputFlightStatus
     */
    queuedAt: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFlightStatus
     */
    repeaterChannel?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputFlightStatus
     */
    team: number;
    /**
     * 
     * @type {string}
     * @memberof OutputFlightStatus
     */
    teamName: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFlightStatus
     */
    timeoutAt: string;
}

/**
 * Check if a given object implements the OutputFlightStatus interface.
 */
export function instanceOfOutputFlightStatus(value: object): boolean {
    if (!('band' in value)) return false;
    if (!('channel' in value)) return false;
    if (!('freq' in value)) return false;
    if (!('queuedAt' in value)) return false;
    if (!('team' in value)) return false;
    if (!('teamName' in value)) return false;
    if (!('timeoutAt' in value)) return false;
    return true;
}

export function OutputFlightStatusFromJSON(json: any): OutputFlightStatus {
    return OutputFlightStatusFromJSONTyped(json, false);
}

export function OutputFlightStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputFlightStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'band': json['band'],
        'band2': json['band2'] == null ? undefined : json['band2'],
        'channel': json['channel'],
        'channel2': json['channel2'] == null ? undefined : json['channel2'],
        'freq': json['freq'],
        'freq2': json['freq2'] == null ? undefined : json['freq2'],
        'queuedAt': json['queuedAt'],
        'repeaterChannel': json['repeaterChannel'] == null ? undefined : json['repeaterChannel'],
        'team': json['team'],
        'teamName': json['teamName'],
        'timeoutAt': json['timeoutAt'],
    };
}

export function OutputFlightStatusToJSON(value?: OutputFlightStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'band': value['band'],
        'band2': value['band2'],
        'channel': value['channel'],
        'channel2': value['channel2'],
        'freq': value['freq'],
        'freq2': value['freq2'],
        'queuedAt': value['queuedAt'],
        'repeaterChannel': value['repeaterChannel'],
        'team': value['team'],
        'teamName': value['teamName'],
        'timeoutAt': value['timeoutAt'],
    };
}

