/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputAdminZone
 */
export interface OutputAdminZone {
    /**
     * 
     * @type {boolean}
     * @memberof OutputAdminZone
     */
    allowAdditionalMissionTime: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminZone
     */
    deltaLink: string;
    /**
     * 
     * @type {number}
     * @memberof OutputAdminZone
     */
    flightTimeoutMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof OutputAdminZone
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof OutputAdminZone
     */
    leftZoneId?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminZone
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof OutputAdminZone
     */
    otgId: number;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminZone
     */
    otgName: string;
    /**
     * 
     * @type {number}
     * @memberof OutputAdminZone
     */
    rightZoneId?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminZone
     */
    signalLink: string;
}

/**
 * Check if a given object implements the OutputAdminZone interface.
 */
export function instanceOfOutputAdminZone(value: object): boolean {
    if (!('allowAdditionalMissionTime' in value)) return false;
    if (!('deltaLink' in value)) return false;
    if (!('flightTimeoutMinutes' in value)) return false;
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('otgId' in value)) return false;
    if (!('otgName' in value)) return false;
    if (!('signalLink' in value)) return false;
    return true;
}

export function OutputAdminZoneFromJSON(json: any): OutputAdminZone {
    return OutputAdminZoneFromJSONTyped(json, false);
}

export function OutputAdminZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputAdminZone {
    if (json == null) {
        return json;
    }
    return {
        
        'allowAdditionalMissionTime': json['allowAdditionalMissionTime'],
        'deltaLink': json['deltaLink'],
        'flightTimeoutMinutes': json['flightTimeoutMinutes'],
        'id': json['id'],
        'leftZoneId': json['leftZoneId'] == null ? undefined : json['leftZoneId'],
        'name': json['name'],
        'otgId': json['otgId'],
        'otgName': json['otgName'],
        'rightZoneId': json['rightZoneId'] == null ? undefined : json['rightZoneId'],
        'signalLink': json['signalLink'],
    };
}

export function OutputAdminZoneToJSON(value?: OutputAdminZone | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allowAdditionalMissionTime': value['allowAdditionalMissionTime'],
        'deltaLink': value['deltaLink'],
        'flightTimeoutMinutes': value['flightTimeoutMinutes'],
        'id': value['id'],
        'leftZoneId': value['leftZoneId'],
        'name': value['name'],
        'otgId': value['otgId'],
        'otgName': value['otgName'],
        'rightZoneId': value['rightZoneId'],
        'signalLink': value['signalLink'],
    };
}

