import { default as activityWUuyIiuwvsMeta } from "/opt/render/project/src/pages/admin/activity.vue?macro=true";
import { default as ammo83IP7mpszbMeta } from "/opt/render/project/src/pages/admin/ammo.vue?macro=true";
import { default as divisionsXEC0BiaernMeta } from "/opt/render/project/src/pages/admin/divisions.vue?macro=true";
import { default as faqsdBCw4jNrI7Meta } from "/opt/render/project/src/pages/admin/faqs.vue?macro=true";
import { default as osgYbAmrwSwNRMeta } from "/opt/render/project/src/pages/admin/osg.vue?macro=true";
import { default as otgpf0qKpHhS5Meta } from "/opt/render/project/src/pages/admin/otg.vue?macro=true";
import { default as teamsY5i2NdU44QMeta } from "/opt/render/project/src/pages/admin/teams.vue?macro=true";
import { default as users38pP4QhTg3Meta } from "/opt/render/project/src/pages/admin/users.vue?macro=true";
import { default as zonestYHzVHSihOMeta } from "/opt/render/project/src/pages/admin/zones.vue?macro=true";
import { default as sessionseREuB7s8pNMeta } from "/opt/render/project/src/pages/division-admin/sessions.vue?macro=true";
import { default as targetssemP2hn1MqMeta } from "/opt/render/project/src/pages/division-admin/targets.vue?macro=true";
import { default as zone_45requestsbmQGkOaeN3Meta } from "/opt/render/project/src/pages/division-admin/zone-requests.vue?macro=true";
import { default as flylOPk3mnmV1Meta } from "/opt/render/project/src/pages/fly.vue?macro=true";
import { default as helpH17oDUVH9TMeta } from "/opt/render/project/src/pages/help.vue?macro=true";
import { default as historyhUV4Mp0SamMeta } from "/opt/render/project/src/pages/history.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as activeKQpYMbZrhAMeta } from "/opt/render/project/src/pages/zone-admin/active.vue?macro=true";
import { default as inactiveJVZV5Kkr2EMeta } from "/opt/render/project/src/pages/zone-admin/inactive.vue?macro=true";
export default [
  {
    name: "admin-activity",
    path: "/admin/activity",
    component: () => import("/opt/render/project/src/pages/admin/activity.vue")
  },
  {
    name: "admin-ammo",
    path: "/admin/ammo",
    component: () => import("/opt/render/project/src/pages/admin/ammo.vue")
  },
  {
    name: "admin-divisions",
    path: "/admin/divisions",
    component: () => import("/opt/render/project/src/pages/admin/divisions.vue")
  },
  {
    name: "admin-faqs",
    path: "/admin/faqs",
    component: () => import("/opt/render/project/src/pages/admin/faqs.vue")
  },
  {
    name: "admin-osg",
    path: "/admin/osg",
    component: () => import("/opt/render/project/src/pages/admin/osg.vue")
  },
  {
    name: "admin-otg",
    path: "/admin/otg",
    component: () => import("/opt/render/project/src/pages/admin/otg.vue")
  },
  {
    name: "admin-teams",
    path: "/admin/teams",
    component: () => import("/opt/render/project/src/pages/admin/teams.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/opt/render/project/src/pages/admin/users.vue")
  },
  {
    name: "admin-zones",
    path: "/admin/zones",
    component: () => import("/opt/render/project/src/pages/admin/zones.vue")
  },
  {
    name: "division-admin-sessions",
    path: "/division-admin/sessions",
    component: () => import("/opt/render/project/src/pages/division-admin/sessions.vue")
  },
  {
    name: "division-admin-targets",
    path: "/division-admin/targets",
    component: () => import("/opt/render/project/src/pages/division-admin/targets.vue")
  },
  {
    name: "division-admin-zone-requests",
    path: "/division-admin/zone-requests",
    component: () => import("/opt/render/project/src/pages/division-admin/zone-requests.vue")
  },
  {
    name: "fly",
    path: "/fly",
    component: () => import("/opt/render/project/src/pages/fly.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/opt/render/project/src/pages/help.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: historyhUV4Mp0SamMeta || {},
    component: () => import("/opt/render/project/src/pages/history.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexYy8SidDWaAMeta || {},
    component: () => import("/opt/render/project/src/pages/index.vue")
  },
  {
    name: "zone-admin-active",
    path: "/zone-admin/active",
    component: () => import("/opt/render/project/src/pages/zone-admin/active.vue")
  },
  {
    name: "zone-admin-inactive",
    path: "/zone-admin/inactive",
    component: () => import("/opt/render/project/src/pages/zone-admin/inactive.vue")
  }
]