/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputHistoryEvent } from './OutputHistoryEvent';
import {
    OutputHistoryEventFromJSON,
    OutputHistoryEventFromJSONTyped,
    OutputHistoryEventToJSON,
} from './OutputHistoryEvent';

/**
 * 
 * @export
 * @interface OutputHistoryResult
 */
export interface OutputHistoryResult {
    /**
     * 
     * @type {Array<OutputHistoryEvent>}
     * @memberof OutputHistoryResult
     */
    items: Array<OutputHistoryEvent>;
    /**
     * 
     * @type {number}
     * @memberof OutputHistoryResult
     */
    totalItems: number;
}

/**
 * Check if a given object implements the OutputHistoryResult interface.
 */
export function instanceOfOutputHistoryResult(value: object): boolean {
    if (!('items' in value)) return false;
    if (!('totalItems' in value)) return false;
    return true;
}

export function OutputHistoryResultFromJSON(json: any): OutputHistoryResult {
    return OutputHistoryResultFromJSONTyped(json, false);
}

export function OutputHistoryResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputHistoryResult {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(OutputHistoryEventFromJSON)),
        'totalItems': json['totalItems'],
    };
}

export function OutputHistoryResultToJSON(value?: OutputHistoryResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(OutputHistoryEventToJSON)),
        'totalItems': value['totalItems'],
    };
}

