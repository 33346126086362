/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputWalkthroughStatus
 */
export interface OutputWalkthroughStatus {
    /**
     * 
     * @type {boolean}
     * @memberof OutputWalkthroughStatus
     */
    pilots?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputWalkthroughStatus
     */
    teamZone?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OutputWalkthroughStatus
     */
    zoneRequest?: boolean;
}

/**
 * Check if a given object implements the OutputWalkthroughStatus interface.
 */
export function instanceOfOutputWalkthroughStatus(value: object): boolean {
    return true;
}

export function OutputWalkthroughStatusFromJSON(json: any): OutputWalkthroughStatus {
    return OutputWalkthroughStatusFromJSONTyped(json, false);
}

export function OutputWalkthroughStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputWalkthroughStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'pilots': json['pilots'] == null ? undefined : json['pilots'],
        'teamZone': json['teamZone'] == null ? undefined : json['teamZone'],
        'zoneRequest': json['zoneRequest'] == null ? undefined : json['zoneRequest'],
    };
}

export function OutputWalkthroughStatusToJSON(value?: OutputWalkthroughStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pilots': value['pilots'],
        'teamZone': value['teamZone'],
        'zoneRequest': value['zoneRequest'],
    };
}

