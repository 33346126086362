/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputZoneAdminRequest
 */
export interface OutputZoneAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof OutputZoneAdminRequest
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputZoneAdminRequest
     */
    division?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputZoneAdminRequest
     */
    divisionId?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputZoneAdminRequest
     */
    id?: number;
}

/**
 * Check if a given object implements the OutputZoneAdminRequest interface.
 */
export function instanceOfOutputZoneAdminRequest(value: object): boolean {
    return true;
}

export function OutputZoneAdminRequestFromJSON(json: any): OutputZoneAdminRequest {
    return OutputZoneAdminRequestFromJSONTyped(json, false);
}

export function OutputZoneAdminRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputZoneAdminRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'] == null ? undefined : json['comment'],
        'division': json['division'] == null ? undefined : json['division'],
        'divisionId': json['divisionId'] == null ? undefined : json['divisionId'],
        'id': json['id'] == null ? undefined : json['id'],
    };
}

export function OutputZoneAdminRequestToJSON(value?: OutputZoneAdminRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'comment': value['comment'],
        'division': value['division'],
        'divisionId': value['divisionId'],
        'id': value['id'],
    };
}

