/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputLastFlightData
 */
export interface OutputLastFlightData {
    /**
     * 
     * @type {string}
     * @memberof OutputLastFlightData
     */
    band: string;
    /**
     * 
     * @type {string}
     * @memberof OutputLastFlightData
     */
    band2?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputLastFlightData
     */
    channel: string;
    /**
     * 
     * @type {string}
     * @memberof OutputLastFlightData
     */
    channel2?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputLastFlightData
     */
    pilot: number;
    /**
     * 
     * @type {string}
     * @memberof OutputLastFlightData
     */
    repeaterChannel?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OutputLastFlightData
     */
    withRepeater: boolean;
    /**
     * 
     * @type {number}
     * @memberof OutputLastFlightData
     */
    zone: number;
}

/**
 * Check if a given object implements the OutputLastFlightData interface.
 */
export function instanceOfOutputLastFlightData(value: object): boolean {
    if (!('band' in value)) return false;
    if (!('channel' in value)) return false;
    if (!('pilot' in value)) return false;
    if (!('withRepeater' in value)) return false;
    if (!('zone' in value)) return false;
    return true;
}

export function OutputLastFlightDataFromJSON(json: any): OutputLastFlightData {
    return OutputLastFlightDataFromJSONTyped(json, false);
}

export function OutputLastFlightDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputLastFlightData {
    if (json == null) {
        return json;
    }
    return {
        
        'band': json['band'],
        'band2': json['band2'] == null ? undefined : json['band2'],
        'channel': json['channel'],
        'channel2': json['channel2'] == null ? undefined : json['channel2'],
        'pilot': json['pilot'],
        'repeaterChannel': json['repeaterChannel'] == null ? undefined : json['repeaterChannel'],
        'withRepeater': json['withRepeater'],
        'zone': json['zone'],
    };
}

export function OutputLastFlightDataToJSON(value?: OutputLastFlightData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'band': value['band'],
        'band2': value['band2'],
        'channel': value['channel'],
        'channel2': value['channel2'],
        'pilot': value['pilot'],
        'repeaterChannel': value['repeaterChannel'],
        'withRepeater': value['withRepeater'],
        'zone': value['zone'],
    };
}

