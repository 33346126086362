/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputTargetListItemData } from './OutputTargetListItemData';
import {
    OutputTargetListItemDataFromJSON,
    OutputTargetListItemDataFromJSONTyped,
    OutputTargetListItemDataToJSON,
} from './OutputTargetListItemData';

/**
 * 
 * @export
 * @interface OutputTargetsResult
 */
export interface OutputTargetsResult {
    /**
     * 
     * @type {Array<OutputTargetListItemData>}
     * @memberof OutputTargetsResult
     */
    items?: Array<OutputTargetListItemData>;
    /**
     * 
     * @type {number}
     * @memberof OutputTargetsResult
     */
    totalItems?: number;
}

/**
 * Check if a given object implements the OutputTargetsResult interface.
 */
export function instanceOfOutputTargetsResult(value: object): boolean {
    return true;
}

export function OutputTargetsResultFromJSON(json: any): OutputTargetsResult {
    return OutputTargetsResultFromJSONTyped(json, false);
}

export function OutputTargetsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputTargetsResult {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(OutputTargetListItemDataFromJSON)),
        'totalItems': json['totalItems'] == null ? undefined : json['totalItems'],
    };
}

export function OutputTargetsResultToJSON(value?: OutputTargetsResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(OutputTargetListItemDataToJSON)),
        'totalItems': value['totalItems'],
    };
}

