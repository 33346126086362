/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputFaq
 */
export interface OutputFaq {
    /**
     * 
     * @type {string}
     * @memberof OutputFaq
     */
    blockType: string;
    /**
     * 
     * @type {number}
     * @memberof OutputFaq
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof OutputFaq
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof OutputFaq
     */
    section: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFaq
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFaq
     */
    title: string;
}

/**
 * Check if a given object implements the OutputFaq interface.
 */
export function instanceOfOutputFaq(value: object): boolean {
    if (!('blockType' in value)) return false;
    if (!('id' in value)) return false;
    if (!('priority' in value)) return false;
    if (!('section' in value)) return false;
    if (!('text' in value)) return false;
    if (!('title' in value)) return false;
    return true;
}

export function OutputFaqFromJSON(json: any): OutputFaq {
    return OutputFaqFromJSONTyped(json, false);
}

export function OutputFaqFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputFaq {
    if (json == null) {
        return json;
    }
    return {
        
        'blockType': json['blockType'],
        'id': json['id'],
        'priority': json['priority'],
        'section': json['section'],
        'text': json['text'],
        'title': json['title'],
    };
}

export function OutputFaqToJSON(value?: OutputFaq | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'blockType': value['blockType'],
        'id': value['id'],
        'priority': value['priority'],
        'section': value['section'],
        'text': value['text'],
        'title': value['title'],
    };
}

