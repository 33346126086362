/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputApproveTeamSession
 */
export interface InputApproveTeamSession {
    /**
     * 
     * @type {number}
     * @memberof InputApproveTeamSession
     */
    hours: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof InputApproveTeamSession
     */
    pilots: Array<number>;
}

/**
 * Check if a given object implements the InputApproveTeamSession interface.
 */
export function instanceOfInputApproveTeamSession(value: object): boolean {
    if (!('hours' in value)) return false;
    if (!('pilots' in value)) return false;
    return true;
}

export function InputApproveTeamSessionFromJSON(json: any): InputApproveTeamSession {
    return InputApproveTeamSessionFromJSONTyped(json, false);
}

export function InputApproveTeamSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputApproveTeamSession {
    if (json == null) {
        return json;
    }
    return {
        
        'hours': json['hours'],
        'pilots': json['pilots'],
    };
}

export function InputApproveTeamSessionToJSON(value?: InputApproveTeamSession | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hours': value['hours'],
        'pilots': value['pilots'],
    };
}

