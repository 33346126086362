/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputMissionResult
 */
export interface InputMissionResult {
    /**
     * 
     * @type {number}
     * @memberof InputMissionResult
     */
    ammo?: number;
    /**
     * 
     * @type {string}
     * @memberof InputMissionResult
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof InputMissionResult
     */
    customTarget?: string;
    /**
     * 
     * @type {number}
     * @memberof InputMissionResult
     */
    target?: number;
    /**
     * 
     * @type {string}
     * @memberof InputMissionResult
     */
    targetStatus: string;
}

/**
 * Check if a given object implements the InputMissionResult interface.
 */
export function instanceOfInputMissionResult(value: object): boolean {
    if (!('targetStatus' in value)) return false;
    return true;
}

export function InputMissionResultFromJSON(json: any): InputMissionResult {
    return InputMissionResultFromJSONTyped(json, false);
}

export function InputMissionResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputMissionResult {
    if (json == null) {
        return json;
    }
    return {
        
        'ammo': json['ammo'] == null ? undefined : json['ammo'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'customTarget': json['custom_target'] == null ? undefined : json['custom_target'],
        'target': json['target'] == null ? undefined : json['target'],
        'targetStatus': json['target_status'],
    };
}

export function InputMissionResultToJSON(value?: InputMissionResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ammo': value['ammo'],
        'comment': value['comment'],
        'custom_target': value['customTarget'],
        'target': value['target'],
        'target_status': value['targetStatus'],
    };
}

