/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputAdminTeam
 */
export interface OutputAdminTeam {
    /**
     * 
     * @type {boolean}
     * @memberof OutputAdminTeam
     */
    allowExtraChannel?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminTeam
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputAdminTeam
     */
    division: number;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminTeam
     */
    divisionName: string;
    /**
     * 
     * @type {number}
     * @memberof OutputAdminTeam
     */
    flightTimeoutMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminTeam
     */
    freqRange?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputAdminTeam
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminTeam
     */
    name: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof OutputAdminTeam
     */
    zones: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminTeam
     */
    zonesTitle: string;
}

/**
 * Check if a given object implements the OutputAdminTeam interface.
 */
export function instanceOfOutputAdminTeam(value: object): boolean {
    if (!('division' in value)) return false;
    if (!('divisionName' in value)) return false;
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('zones' in value)) return false;
    if (!('zonesTitle' in value)) return false;
    return true;
}

export function OutputAdminTeamFromJSON(json: any): OutputAdminTeam {
    return OutputAdminTeamFromJSONTyped(json, false);
}

export function OutputAdminTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputAdminTeam {
    if (json == null) {
        return json;
    }
    return {
        
        'allowExtraChannel': json['allowExtraChannel'] == null ? undefined : json['allowExtraChannel'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'division': json['division'],
        'divisionName': json['divisionName'],
        'flightTimeoutMinutes': json['flightTimeoutMinutes'] == null ? undefined : json['flightTimeoutMinutes'],
        'freqRange': json['freqRange'] == null ? undefined : json['freqRange'],
        'id': json['id'],
        'name': json['name'],
        'zones': json['zones'],
        'zonesTitle': json['zonesTitle'],
    };
}

export function OutputAdminTeamToJSON(value?: OutputAdminTeam | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allowExtraChannel': value['allowExtraChannel'],
        'comment': value['comment'],
        'division': value['division'],
        'divisionName': value['divisionName'],
        'flightTimeoutMinutes': value['flightTimeoutMinutes'],
        'freqRange': value['freqRange'],
        'id': value['id'],
        'name': value['name'],
        'zones': value['zones'],
        'zonesTitle': value['zonesTitle'],
    };
}

