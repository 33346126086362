import revive_payload_client_4sVQNw7RlN from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/render/project/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/render/project/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/opt/render/project/src/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/render/project/src/.nuxt/sentry-client-config.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/opt/render/project/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/opt/render/project/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chartjs_1wC9lAM0Tp from "/opt/render/project/src/plugins/chartjs.ts";
import vuetify_7h9QAQEssH from "/opt/render/project/src/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  chartjs_1wC9lAM0Tp,
  vuetify_7h9QAQEssH
]