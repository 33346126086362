/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputZone
 */
export interface OutputZone {
    /**
     * 
     * @type {boolean}
     * @memberof OutputZone
     */
    allowAdditionalMissionTime: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputZone
     */
    deltaLink: string;
    /**
     * 
     * @type {number}
     * @memberof OutputZone
     */
    flightTimeoutMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof OutputZone
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OutputZone
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OutputZone
     */
    signalLink: string;
}

/**
 * Check if a given object implements the OutputZone interface.
 */
export function instanceOfOutputZone(value: object): boolean {
    if (!('allowAdditionalMissionTime' in value)) return false;
    if (!('deltaLink' in value)) return false;
    if (!('flightTimeoutMinutes' in value)) return false;
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('signalLink' in value)) return false;
    return true;
}

export function OutputZoneFromJSON(json: any): OutputZone {
    return OutputZoneFromJSONTyped(json, false);
}

export function OutputZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputZone {
    if (json == null) {
        return json;
    }
    return {
        
        'allowAdditionalMissionTime': json['allowAdditionalMissionTime'],
        'deltaLink': json['deltaLink'],
        'flightTimeoutMinutes': json['flightTimeoutMinutes'],
        'id': json['id'],
        'name': json['name'],
        'signalLink': json['signalLink'],
    };
}

export function OutputZoneToJSON(value?: OutputZone | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allowAdditionalMissionTime': value['allowAdditionalMissionTime'],
        'deltaLink': value['deltaLink'],
        'flightTimeoutMinutes': value['flightTimeoutMinutes'],
        'id': value['id'],
        'name': value['name'],
        'signalLink': value['signalLink'],
    };
}

