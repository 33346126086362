/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputAdminUser
 */
export interface InputAdminUser {
    /**
     * 
     * @type {string}
     * @memberof InputAdminUser
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof InputAdminUser
     */
    division?: number;
    /**
     * 
     * @type {string}
     * @memberof InputAdminUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof InputAdminUser
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof InputAdminUser
     */
    otg?: number;
    /**
     * 
     * @type {string}
     * @memberof InputAdminUser
     */
    role: string;
    /**
     * 
     * @type {number}
     * @memberof InputAdminUser
     */
    zone?: number;
}

/**
 * Check if a given object implements the InputAdminUser interface.
 */
export function instanceOfInputAdminUser(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('name' in value)) return false;
    if (!('role' in value)) return false;
    return true;
}

export function InputAdminUserFromJSON(json: any): InputAdminUser {
    return InputAdminUserFromJSONTyped(json, false);
}

export function InputAdminUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputAdminUser {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'] == null ? undefined : json['comment'],
        'division': json['division'] == null ? undefined : json['division'],
        'email': json['email'],
        'name': json['name'],
        'otg': json['otg'] == null ? undefined : json['otg'],
        'role': json['role'],
        'zone': json['zone'] == null ? undefined : json['zone'],
    };
}

export function InputAdminUserToJSON(value?: InputAdminUser | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'comment': value['comment'],
        'division': value['division'],
        'email': value['email'],
        'name': value['name'],
        'otg': value['otg'],
        'role': value['role'],
        'zone': value['zone'],
    };
}

