/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputTeamsSession } from './OutputTeamsSession';
import {
    OutputTeamsSessionFromJSON,
    OutputTeamsSessionFromJSONTyped,
    OutputTeamsSessionToJSON,
} from './OutputTeamsSession';

/**
 * 
 * @export
 * @interface OutputSessionsData
 */
export interface OutputSessionsData {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OutputSessionsData
     */
    pilots: { [key: string]: string; };
    /**
     * 
     * @type {Array<OutputTeamsSession>}
     * @memberof OutputSessionsData
     */
    sessions: Array<OutputTeamsSession>;
}

/**
 * Check if a given object implements the OutputSessionsData interface.
 */
export function instanceOfOutputSessionsData(value: object): boolean {
    if (!('pilots' in value)) return false;
    if (!('sessions' in value)) return false;
    return true;
}

export function OutputSessionsDataFromJSON(json: any): OutputSessionsData {
    return OutputSessionsDataFromJSONTyped(json, false);
}

export function OutputSessionsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputSessionsData {
    if (json == null) {
        return json;
    }
    return {
        
        'pilots': json['pilots'],
        'sessions': ((json['sessions'] as Array<any>).map(OutputTeamsSessionFromJSON)),
    };
}

export function OutputSessionsDataToJSON(value?: OutputSessionsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pilots': value['pilots'],
        'sessions': ((value['sessions'] as Array<any>).map(OutputTeamsSessionToJSON)),
    };
}

