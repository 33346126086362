<script setup lang="ts">
const props = defineProps(['text', 'modelValue', 'timeout'])
const emit = defineEmits(['update:modelValue'])
const value = ref(props.modelValue)
watch(props, (val: {modelValue: any}) => {
  if (val.modelValue !== value.value) {
    value.value = val.modelValue
  }
})

watch(value, (newValue) => {
  if (!newValue) {
    emit('update:modelValue', false)
  }
})
const timeout = props.timeout || 5000
</script>

<template>
  <v-snackbar
      v-model="value"
      :timeout="timeout"
      color="error"
  >
    {{props.text}}
    <template v-slot:actions>
      <v-btn
          color="white"
          variant="text"
          @click="value=false;emit('update:modelValue', false)"
      >
        <v-icon
            icon="mdi-close"
            size="large"
        ></v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<style scoped>

</style>