import type {Ref} from "vue"
import {useApi} from "./useApi"

export const usePermissions = () => {
    const api = useApi()
    const permissions: Ref<string[]> = useState("permissions", () => ([]))
    const isPermissionsLoaded: Ref<boolean> = useState('isPermissionsLoaded', () => false)

    const loadPermissions = async () => {
        permissions.value = await api.getAuthPermissions()
        //
        // const preparedObject = []
        // newPermissions.forEach(item => {
        //     preparedObject[item] = true
        //     isPermissionsLoaded.value = true
        // })
        // permissions.value = preparedObject
    }

    const isAuthorized = (permission: string): boolean => {
        return permissions.value.includes(permission)
    }

    const unloadPermissions = () => {
        permissions.value = []
    }

    return {
        isAuthorized,
        permissions,
        loadPermissions,
        unloadPermissions,
    }
}