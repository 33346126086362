/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputBrowsersInfo
 */
export interface InputBrowsersInfo {
    /**
     * 
     * @type {string}
     * @memberof InputBrowsersInfo
     */
    browserName: string;
    /**
     * 
     * @type {string}
     * @memberof InputBrowsersInfo
     */
    deviceType: string;
    /**
     * 
     * @type {number}
     * @memberof InputBrowsersInfo
     */
    screenHeight: number;
    /**
     * 
     * @type {number}
     * @memberof InputBrowsersInfo
     */
    screenWidth: number;
}

/**
 * Check if a given object implements the InputBrowsersInfo interface.
 */
export function instanceOfInputBrowsersInfo(value: object): boolean {
    if (!('browserName' in value)) return false;
    if (!('deviceType' in value)) return false;
    if (!('screenHeight' in value)) return false;
    if (!('screenWidth' in value)) return false;
    return true;
}

export function InputBrowsersInfoFromJSON(json: any): InputBrowsersInfo {
    return InputBrowsersInfoFromJSONTyped(json, false);
}

export function InputBrowsersInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputBrowsersInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'browserName': json['browserName'],
        'deviceType': json['deviceType'],
        'screenHeight': json['screenHeight'],
        'screenWidth': json['screenWidth'],
    };
}

export function InputBrowsersInfoToJSON(value?: InputBrowsersInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'browserName': value['browserName'],
        'deviceType': value['deviceType'],
        'screenHeight': value['screenHeight'],
        'screenWidth': value['screenWidth'],
    };
}

