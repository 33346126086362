import type {Ref} from "vue";

export const useApiErrors = () => {
    const {t} = useNuxtApp().$i18n

    const errorState: Ref<boolean> = useState('errorNotification', () => false)
    const errorMessage: Ref<null | string> = useState('apiError')
    const showApiError = (status: number, error: any) => {
        let msgKey = ''
        if (error?.response?.headers?.get('x-msg-key')) {
            // У випадках виклику через $fetch.catch(err => ...)
            msgKey = error?.response?.headers?.get('x-msg-key')
        } else if (error?.headers?.get('x-msg-key') && error.headers.get('x-msg-key').match(/^\w+$/)) {
            // У випадках api.callSwaggerMethod(...)
            msgKey = error.headers.get('x-msg-key')
        }

        if (msgKey) {
            errorMessage.value = t('api.messages.' + msgKey)
        } else if (status >= 500 && status < 599) {
            errorMessage.value = t('api.somethingWentWrong')
        } else if (status == 409) {
            errorMessage.value = t('api.errors.' + error.data.replace(/[^\w+]/g, '_').toLocaleLowerCase())
        } else if (status == 401) {
            errorMessage.value = t('api.errors.unauthorized')
        } else if (status >= 400 && status < 499) {
            errorMessage.value = t('api.clientRequestError')
        } else {
            console.log('Error', error)
        }
    }

    watch(errorMessage, (error) => {
        if (!!error) {
            errorState.value = true
        }
    })
    watch(errorState, (newVal) => {
        if (!newVal) {
            errorMessage.value = null
        }
    })

    return {
        errorMessage,
        errorState,
        showApiError
    }
}