/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputZoneRequest
 */
export interface InputZoneRequest {
    /**
     * 
     * @type {string}
     * @memberof InputZoneRequest
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof InputZoneRequest
     */
    coords?: string;
    /**
     * 
     * @type {string}
     * @memberof InputZoneRequest
     */
    deltaLogin?: string;
    /**
     * 
     * @type {number}
     * @memberof InputZoneRequest
     */
    otg?: number;
}

/**
 * Check if a given object implements the InputZoneRequest interface.
 */
export function instanceOfInputZoneRequest(value: object): boolean {
    return true;
}

export function InputZoneRequestFromJSON(json: any): InputZoneRequest {
    return InputZoneRequestFromJSONTyped(json, false);
}

export function InputZoneRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputZoneRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'] == null ? undefined : json['comment'],
        'coords': json['coords'] == null ? undefined : json['coords'],
        'deltaLogin': json['delta_login'] == null ? undefined : json['delta_login'],
        'otg': json['otg'] == null ? undefined : json['otg'],
    };
}

export function InputZoneRequestToJSON(value?: InputZoneRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'comment': value['comment'],
        'coords': value['coords'],
        'delta_login': value['deltaLogin'],
        'otg': value['otg'],
    };
}

