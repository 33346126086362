// import this after install `@mdi/font` package
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { VNumberInput } from 'vuetify/labs/VNumberInput'

export default defineNuxtPlugin((app) => {

    const vuetify = createVuetify({
        // ... your config
        components: {
            VNumberInput,
        },
        theme: {
            defaultTheme: parseInt(localStorage.getItem('blackTheme') || '0') ? 'dark' : 'light',

            themes: {
                light: {
                    colors: {
                        primary: '#00897B', // teal-darken-1
                        error: '#B71C1C', // red-darken-4
                        "app-bar": '#37474F',
                        "app-loader": "#546E7A",
                        shaded: "#757575",
                        background: '#F5F5F5',
                        "panels-with-cards": '#EEEEEE',
                    },
                },
                dark: {
                    colors: {
                        primary: '#26A69A', // teal-lighten-2
                        error: '#E57373', // red-lighten-2
                        "app-bar": '#212B30',
                        "app-loader": "#455A64",
                        shaded: "#BDBDBD",
                        "panels-with-cards": '#424242',
                    }
                }
            }
        },
    });
    app.vueApp.use(vuetify);
});