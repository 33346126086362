/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputTargetData
 */
export interface OutputTargetData {
    /**
     * 
     * @type {boolean}
     * @memberof OutputTargetData
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputTargetData
     */
    coords: string;
    /**
     * 
     * @type {string}
     * @memberof OutputTargetData
     */
    customTargetType: string;
    /**
     * 
     * @type {string}
     * @memberof OutputTargetData
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof OutputTargetData
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof OutputTargetData
     */
    targetType: number;
    /**
     * 
     * @type {number}
     * @memberof OutputTargetData
     */
    zone: number;
}

/**
 * Check if a given object implements the OutputTargetData interface.
 */
export function instanceOfOutputTargetData(value: object): boolean {
    if (!('active' in value)) return false;
    if (!('coords' in value)) return false;
    if (!('customTargetType' in value)) return false;
    if (!('description' in value)) return false;
    if (!('id' in value)) return false;
    if (!('targetType' in value)) return false;
    if (!('zone' in value)) return false;
    return true;
}

export function OutputTargetDataFromJSON(json: any): OutputTargetData {
    return OutputTargetDataFromJSONTyped(json, false);
}

export function OutputTargetDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputTargetData {
    if (json == null) {
        return json;
    }
    return {
        
        'active': json['active'],
        'coords': json['coords'],
        'customTargetType': json['custom_target_type'],
        'description': json['description'],
        'id': json['id'],
        'targetType': json['target_type'],
        'zone': json['zone'],
    };
}

export function OutputTargetDataToJSON(value?: OutputTargetData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'active': value['active'],
        'coords': value['coords'],
        'custom_target_type': value['customTargetType'],
        'description': value['description'],
        'id': value['id'],
        'target_type': value['targetType'],
        'zone': value['zone'],
    };
}

