/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputZoneRequestsItemData
 */
export interface OutputZoneRequestsItemData {
    /**
     * 
     * @type {string}
     * @memberof OutputZoneRequestsItemData
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputZoneRequestsItemData
     */
    coords?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputZoneRequestsItemData
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputZoneRequestsItemData
     */
    deltaLogin?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputZoneRequestsItemData
     */
    editedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputZoneRequestsItemData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputZoneRequestsItemData
     */
    otg?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputZoneRequestsItemData
     */
    status?: string;
}

/**
 * Check if a given object implements the OutputZoneRequestsItemData interface.
 */
export function instanceOfOutputZoneRequestsItemData(value: object): boolean {
    return true;
}

export function OutputZoneRequestsItemDataFromJSON(json: any): OutputZoneRequestsItemData {
    return OutputZoneRequestsItemDataFromJSONTyped(json, false);
}

export function OutputZoneRequestsItemDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputZoneRequestsItemData {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'] == null ? undefined : json['comment'],
        'coords': json['coords'] == null ? undefined : json['coords'],
        'createdAt': json['created_at'] == null ? undefined : json['created_at'],
        'deltaLogin': json['delta_login'] == null ? undefined : json['delta_login'],
        'editedAt': json['edited_at'] == null ? undefined : json['edited_at'],
        'id': json['id'] == null ? undefined : json['id'],
        'otg': json['otg'] == null ? undefined : json['otg'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function OutputZoneRequestsItemDataToJSON(value?: OutputZoneRequestsItemData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'comment': value['comment'],
        'coords': value['coords'],
        'created_at': value['createdAt'],
        'delta_login': value['deltaLogin'],
        'edited_at': value['editedAt'],
        'id': value['id'],
        'otg': value['otg'],
        'status': value['status'],
    };
}

