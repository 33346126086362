/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputAdminOTG
 */
export interface OutputAdminOTG {
    /**
     * 
     * @type {number}
     * @memberof OutputAdminOTG
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminOTG
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof OutputAdminOTG
     */
    osgId: number;
    /**
     * 
     * @type {string}
     * @memberof OutputAdminOTG
     */
    osgName: string;
}

/**
 * Check if a given object implements the OutputAdminOTG interface.
 */
export function instanceOfOutputAdminOTG(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('osgId' in value)) return false;
    if (!('osgName' in value)) return false;
    return true;
}

export function OutputAdminOTGFromJSON(json: any): OutputAdminOTG {
    return OutputAdminOTGFromJSONTyped(json, false);
}

export function OutputAdminOTGFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputAdminOTG {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'osgId': json['osgId'],
        'osgName': json['osgName'],
    };
}

export function OutputAdminOTGToJSON(value?: OutputAdminOTG | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'osgId': value['osgId'],
        'osgName': value['osgName'],
    };
}

