/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputZoneRequestsItemData } from './OutputZoneRequestsItemData';
import {
    OutputZoneRequestsItemDataFromJSON,
    OutputZoneRequestsItemDataFromJSONTyped,
    OutputZoneRequestsItemDataToJSON,
} from './OutputZoneRequestsItemData';

/**
 * 
 * @export
 * @interface OutputZoneRequestsResult
 */
export interface OutputZoneRequestsResult {
    /**
     * 
     * @type {Array<OutputZoneRequestsItemData>}
     * @memberof OutputZoneRequestsResult
     */
    items?: Array<OutputZoneRequestsItemData>;
    /**
     * 
     * @type {number}
     * @memberof OutputZoneRequestsResult
     */
    totalItems?: number;
}

/**
 * Check if a given object implements the OutputZoneRequestsResult interface.
 */
export function instanceOfOutputZoneRequestsResult(value: object): boolean {
    return true;
}

export function OutputZoneRequestsResultFromJSON(json: any): OutputZoneRequestsResult {
    return OutputZoneRequestsResultFromJSONTyped(json, false);
}

export function OutputZoneRequestsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputZoneRequestsResult {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(OutputZoneRequestsItemDataFromJSON)),
        'totalItems': json['totalItems'] == null ? undefined : json['totalItems'],
    };
}

export function OutputZoneRequestsResultToJSON(value?: OutputZoneRequestsResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(OutputZoneRequestsItemDataToJSON)),
        'totalItems': value['totalItems'],
    };
}

