/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InputAdminAmmo,
  InputAdminDivision,
  InputAdminOSG,
  InputAdminOTG,
  InputAdminTeam,
  InputAdminUser,
  InputAdminZone,
  InputApproveTeamSession,
  InputAuthToken,
  InputBrowsersInfo,
  InputEditAdminUser,
  InputFAQ,
  InputMissionResult,
  InputMissionVideo,
  InputStartSession,
  InputTarget,
  InputUserProfile,
  InputZoneAdminZone,
  InputZoneRequest,
  OutputAdminAmmo,
  OutputAdminDivision,
  OutputAdminOSG,
  OutputAdminOTG,
  OutputAdminRoleSelector,
  OutputAdminTeam,
  OutputAdminTeamsZonesSelector,
  OutputAdminUser,
  OutputAdminUsersOtgsSelector,
  OutputAdminUsersZonesSelector,
  OutputAdminZone,
  OutputAmmo,
  OutputAnalyticsOnlineUsers,
  OutputAnalyticsTopDivisions,
  OutputBookingData,
  OutputChannelsMap,
  OutputDivision,
  OutputFaq,
  OutputHistoryEditableData,
  OutputHistoryResult,
  OutputLastFlightData,
  OutputMissionData,
  OutputSession,
  OutputSessionsData,
  OutputTargetData,
  OutputTargetSelectData,
  OutputTargetTypesResult,
  OutputTargetsResult,
  OutputUserIdentity,
  OutputUserProfile,
  OutputWalkthroughStatus,
  OutputZone,
  OutputZoneAdminDivision,
  OutputZoneAdminRequest,
  OutputZoneChannelData,
  OutputZoneRequestsOtgsSelector,
  OutputZoneRequestsResult,
  OutputZoneRequestsZonesSelector,
} from '../models/index';
import {
    InputAdminAmmoFromJSON,
    InputAdminAmmoToJSON,
    InputAdminDivisionFromJSON,
    InputAdminDivisionToJSON,
    InputAdminOSGFromJSON,
    InputAdminOSGToJSON,
    InputAdminOTGFromJSON,
    InputAdminOTGToJSON,
    InputAdminTeamFromJSON,
    InputAdminTeamToJSON,
    InputAdminUserFromJSON,
    InputAdminUserToJSON,
    InputAdminZoneFromJSON,
    InputAdminZoneToJSON,
    InputApproveTeamSessionFromJSON,
    InputApproveTeamSessionToJSON,
    InputAuthTokenFromJSON,
    InputAuthTokenToJSON,
    InputBrowsersInfoFromJSON,
    InputBrowsersInfoToJSON,
    InputEditAdminUserFromJSON,
    InputEditAdminUserToJSON,
    InputFAQFromJSON,
    InputFAQToJSON,
    InputMissionResultFromJSON,
    InputMissionResultToJSON,
    InputMissionVideoFromJSON,
    InputMissionVideoToJSON,
    InputStartSessionFromJSON,
    InputStartSessionToJSON,
    InputTargetFromJSON,
    InputTargetToJSON,
    InputUserProfileFromJSON,
    InputUserProfileToJSON,
    InputZoneAdminZoneFromJSON,
    InputZoneAdminZoneToJSON,
    InputZoneRequestFromJSON,
    InputZoneRequestToJSON,
    OutputAdminAmmoFromJSON,
    OutputAdminAmmoToJSON,
    OutputAdminDivisionFromJSON,
    OutputAdminDivisionToJSON,
    OutputAdminOSGFromJSON,
    OutputAdminOSGToJSON,
    OutputAdminOTGFromJSON,
    OutputAdminOTGToJSON,
    OutputAdminRoleSelectorFromJSON,
    OutputAdminRoleSelectorToJSON,
    OutputAdminTeamFromJSON,
    OutputAdminTeamToJSON,
    OutputAdminTeamsZonesSelectorFromJSON,
    OutputAdminTeamsZonesSelectorToJSON,
    OutputAdminUserFromJSON,
    OutputAdminUserToJSON,
    OutputAdminUsersOtgsSelectorFromJSON,
    OutputAdminUsersOtgsSelectorToJSON,
    OutputAdminUsersZonesSelectorFromJSON,
    OutputAdminUsersZonesSelectorToJSON,
    OutputAdminZoneFromJSON,
    OutputAdminZoneToJSON,
    OutputAmmoFromJSON,
    OutputAmmoToJSON,
    OutputAnalyticsOnlineUsersFromJSON,
    OutputAnalyticsOnlineUsersToJSON,
    OutputAnalyticsTopDivisionsFromJSON,
    OutputAnalyticsTopDivisionsToJSON,
    OutputBookingDataFromJSON,
    OutputBookingDataToJSON,
    OutputChannelsMapFromJSON,
    OutputChannelsMapToJSON,
    OutputDivisionFromJSON,
    OutputDivisionToJSON,
    OutputFaqFromJSON,
    OutputFaqToJSON,
    OutputHistoryEditableDataFromJSON,
    OutputHistoryEditableDataToJSON,
    OutputHistoryResultFromJSON,
    OutputHistoryResultToJSON,
    OutputLastFlightDataFromJSON,
    OutputLastFlightDataToJSON,
    OutputMissionDataFromJSON,
    OutputMissionDataToJSON,
    OutputSessionFromJSON,
    OutputSessionToJSON,
    OutputSessionsDataFromJSON,
    OutputSessionsDataToJSON,
    OutputTargetDataFromJSON,
    OutputTargetDataToJSON,
    OutputTargetSelectDataFromJSON,
    OutputTargetSelectDataToJSON,
    OutputTargetTypesResultFromJSON,
    OutputTargetTypesResultToJSON,
    OutputTargetsResultFromJSON,
    OutputTargetsResultToJSON,
    OutputUserIdentityFromJSON,
    OutputUserIdentityToJSON,
    OutputUserProfileFromJSON,
    OutputUserProfileToJSON,
    OutputWalkthroughStatusFromJSON,
    OutputWalkthroughStatusToJSON,
    OutputZoneFromJSON,
    OutputZoneToJSON,
    OutputZoneAdminDivisionFromJSON,
    OutputZoneAdminDivisionToJSON,
    OutputZoneAdminRequestFromJSON,
    OutputZoneAdminRequestToJSON,
    OutputZoneChannelDataFromJSON,
    OutputZoneChannelDataToJSON,
    OutputZoneRequestsOtgsSelectorFromJSON,
    OutputZoneRequestsOtgsSelectorToJSON,
    OutputZoneRequestsResultFromJSON,
    OutputZoneRequestsResultToJSON,
    OutputZoneRequestsZonesSelectorFromJSON,
    OutputZoneRequestsZonesSelectorToJSON,
} from '../models/index';

export interface AddAdditionalMissionTimeRequest {
    id: number;
}

export interface ApproveTeamSessionRequest {
    id: number;
    request: InputApproveTeamSession;
}

export interface ClearZoneQueueRequest {
    zone: number;
}

export interface CreateAdminAmmoRequest {
    request: InputAdminAmmo;
}

export interface CreateAdminDivisionRequest {
    request: InputAdminDivision;
}

export interface CreateAdminOSGRequest {
    request: InputAdminOSG;
}

export interface CreateAdminOTGRequest {
    request: InputAdminOTG;
}

export interface CreateAdminTeamRequest {
    request: InputAdminTeam;
}

export interface CreateAdminUserRequest {
    request: InputAdminUser;
}

export interface CreateAdminZoneRequest {
    request: InputAdminZone;
}

export interface CreateFAQRequest {
    request: InputFAQ;
}

export interface CreateTargetHandlerRequest {
    request: InputTarget;
}

export interface CreateZoneRequestHandlerRequest {
    request: InputZoneRequest;
}

export interface DeleteAdminAmmoRequest {
    id: number;
}

export interface DeleteAdminDivisionRequest {
    id: number;
}

export interface DeleteAdminOSGRequest {
    id: number;
}

export interface DeleteAdminOTGRequest {
    id: number;
}

export interface DeleteAdminTeamRequest {
    id: number;
}

export interface DeleteAdminUserRequest {
    id: number;
}

export interface DeleteAdminZoneRequest {
    id: number;
}

export interface DeleteFAQRequest {
    id: number;
}

export interface DeleteMissionRequest {
    id: number;
}

export interface DeleteTargetRequest {
    id: number;
}

export interface EditMissionResultRequest {
    id: number;
    request: InputMissionResult;
}

export interface EditMissionVideoRequest {
    id: number;
    request: InputMissionVideo;
}

export interface EditTargetHandlerRequest {
    id: number;
    request: InputTarget;
}

export interface FinishSessionRequest {
    id: number;
}

export interface GetAdminAmmoRequest {
    id: number;
}

export interface GetAdminDivisionRequest {
    id: number;
}

export interface GetAdminOSGRequest {
    id: number;
}

export interface GetAdminOTGRequest {
    id: number;
}

export interface GetAdminTeamRequest {
    id: number;
}

export interface GetAdminUserRequest {
    id: number;
}

export interface GetAdminZoneRequest {
    id: number;
}

export interface GetAmmoListForMissionRequest {
    id: number;
}

export interface GetAuthTokenRequest {
    request: InputAuthToken;
}

export interface GetFAQRequest {
    id: number;
}

export interface GetFlyHistoryRequest {
    search?: string;
    sortBy?: string;
    orderBy?: GetFlyHistoryOrderByEnum;
    limit?: number;
    offset?: number;
}

export interface GetFlyZoneChannelDataRequest {
    zone: number;
}

export interface GetMissionEditableDataRequest {
    id: number;
}

export interface GetOnlineChartsDataRequest {
    period: GetOnlineChartsDataPeriodEnum;
}

export interface GetSessionsDataRequest {
    division?: number;
}

export interface GetTargetDataRequest {
    id: number;
}

export interface GetTargetListForMissionRequest {
    id: number;
}

export interface GetTargetsListRequest {
    search?: string;
    sortBy?: string;
    orderBy?: GetTargetsListOrderByEnum;
    limit?: number;
    offset?: number;
}

export interface GetTopDivisionsChartsDataHandlerRequest {
    period: GetTopDivisionsChartsDataHandlerPeriodEnum;
}

export interface GetZoneAdminOtgZonesListRequest {
    id: number;
}

export interface GetZoneAdminRequestRequest {
    id: number;
}

export interface GetZoneRequestsListRequest {
    sortBy?: string;
    orderBy?: GetZoneRequestsListOrderByEnum;
    limit?: number;
    offset?: number;
}

export interface PutBrowserInfoRequest {
    request: InputBrowsersInfo;
}

export interface StartFlySessionRequest {
    request: InputStartSession;
}

export interface UpdateAdminAmmoRequest {
    id: number;
    request: InputAdminAmmo;
}

export interface UpdateAdminOSGRequest {
    id: number;
    request: InputAdminOSG;
}

export interface UpdateAdminOTGRequest {
    id: number;
    request: InputAdminOTG;
}

export interface UpdateAdminTeamRequest {
    id: number;
    request: InputAdminTeam;
}

export interface UpdateAdminUserRequest {
    id: number;
    request: InputEditAdminUser;
}

export interface UpdateAdminZoneRequest {
    id: number;
    request: InputAdminZone;
}

export interface UpdateFAQRequest {
    id: number;
    request: InputFAQ;
}

export interface UpdateUserProfileRequest {
    request: InputUserProfile;
}

export interface UpdatedAdminDivisionRequest {
    id: number;
    request: InputAdminDivision;
}

export interface ZoneAdminApproveRequestRequest {
    id: number;
    request: InputZoneAdminZone;
}

export interface ZoneAdminDeclineRequestRequest {
    id: number;
}

export interface ZoneAdminDeleteDivisionFromZoneRequest {
    divisionId: number;
    zoneId: number;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Додатковий час для польотної місії
     */
    async addAdditionalMissionTimeRaw(requestParameters: AddAdditionalMissionTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addAdditionalMissionTime().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/missions/{id}/additional-time`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Додатковий час для польотної місії
     */
    async addAdditionalMissionTime(requestParameters: AddAdditionalMissionTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.addAdditionalMissionTimeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Затвердження сесії екіпажу, у разі відсутності такої сесії в БД буде створена нова сесія
     * Затвердження сесії
     */
    async approveTeamSessionRaw(requestParameters: ApproveTeamSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling approveTeamSession().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling approveTeamSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sessions/team/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputApproveTeamSessionToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Затвердження сесії екіпажу, у разі відсутності такої сесії в БД буде створена нова сесія
     * Затвердження сесії
     */
    async approveTeamSession(requestParameters: ApproveTeamSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approveTeamSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Очищення черги польотів в рамках зони
     * Очищення черги польотів
     */
    async clearZoneQueueRaw(requestParameters: ClearZoneQueueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['zone'] == null) {
            throw new runtime.RequiredError(
                'zone',
                'Required parameter "zone" was null or undefined when calling clearZoneQueue().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/fly/queue/{zone}`.replace(`{${"zone"}}`, encodeURIComponent(String(requestParameters['zone']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Очищення черги польотів в рамках зони
     * Очищення черги польотів
     */
    async clearZoneQueue(requestParameters: ClearZoneQueueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.clearZoneQueueRaw(requestParameters, initOverrides);
    }

    /**
     * Відкриття потоку для системи сповіщень від серверу за допомогою SSE (Server-sent events)
     * Канал сповіщень
     */
    async connectApiStreamRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/stream`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Відкриття потоку для системи сповіщень від серверу за допомогою SSE (Server-sent events)
     * Канал сповіщень
     */
    async connectApiStream(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.connectApiStreamRaw(initOverrides);
    }

    /**
     * Створення нового БК в адміністративному інтерфейсі
     * Створення БК
     */
    async createAdminAmmoRaw(requestParameters: CreateAdminAmmoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling createAdminAmmo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/ammo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminAmmoToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Створення нового БК в адміністративному інтерфейсі
     * Створення БК
     */
    async createAdminAmmo(requestParameters: CreateAdminAmmoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createAdminAmmoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Створення нового підрозділу в адміністративному інтерфейсі
     * Створення підрозділу
     */
    async createAdminDivisionRaw(requestParameters: CreateAdminDivisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling createAdminDivision().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/division`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminDivisionToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Створення нового підрозділу в адміністративному інтерфейсі
     * Створення підрозділу
     */
    async createAdminDivision(requestParameters: CreateAdminDivisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createAdminDivisionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Створення нової ОСУВ в адміністративному інтерфейсі
     * Створення ОСУВ
     */
    async createAdminOSGRaw(requestParameters: CreateAdminOSGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling createAdminOSG().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/osg`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminOSGToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Створення нової ОСУВ в адміністративному інтерфейсі
     * Створення ОСУВ
     */
    async createAdminOSG(requestParameters: CreateAdminOSGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createAdminOSGRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Створення нової ОТУ в адміністративному інтерфейсі
     * Створення ОТУ
     */
    async createAdminOTGRaw(requestParameters: CreateAdminOTGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling createAdminOTG().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/otg`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminOTGToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Створення нової ОТУ в адміністративному інтерфейсі
     * Створення ОТУ
     */
    async createAdminOTG(requestParameters: CreateAdminOTGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createAdminOTGRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Створення нового екіпажу в адміністративному інтерфейсі
     * Створення екіпажу
     */
    async createAdminTeamRaw(requestParameters: CreateAdminTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling createAdminTeam().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/team`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminTeamToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Створення нового екіпажу в адміністративному інтерфейсі
     * Створення екіпажу
     */
    async createAdminTeam(requestParameters: CreateAdminTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createAdminTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Створення нового користувача в адміністративному інтерфейсі
     * Створення користувача
     */
    async createAdminUserRaw(requestParameters: CreateAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling createAdminUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminUserToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Створення нового користувача в адміністративному інтерфейсі
     * Створення користувача
     */
    async createAdminUser(requestParameters: CreateAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createAdminUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Створення нової зони в адміністративному інтерфейсі
     * Створення зони
     */
    async createAdminZoneRaw(requestParameters: CreateAdminZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling createAdminZone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/zone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminZoneToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Створення нової зони в адміністративному інтерфейсі
     * Створення зони
     */
    async createAdminZone(requestParameters: CreateAdminZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createAdminZoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Створення FAQ запису
     */
    async createFAQRaw(requestParameters: CreateFAQRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling createFAQ().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/faq`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputFAQToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Створення FAQ запису
     */
    async createFAQ(requestParameters: CreateFAQRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createFAQRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Створення цілі
     */
    async createTargetHandlerRaw(requestParameters: CreateTargetHandlerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling createTargetHandler().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/targets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputTargetToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Створення цілі
     */
    async createTargetHandler(requestParameters: CreateTargetHandlerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createTargetHandlerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Створення запиту на приєднання до зони
     */
    async createZoneRequestHandlerRaw(requestParameters: CreateZoneRequestHandlerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling createZoneRequestHandler().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/division/zone-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputZoneRequestToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Створення запиту на приєднання до зони
     */
    async createZoneRequestHandler(requestParameters: CreateZoneRequestHandlerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createZoneRequestHandlerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Видалення БК в адміністративному інтерфейсі
     * Видалення БК
     */
    async deleteAdminAmmoRaw(requestParameters: DeleteAdminAmmoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteAdminAmmo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/ammo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Видалення БК в адміністративному інтерфейсі
     * Видалення БК
     */
    async deleteAdminAmmo(requestParameters: DeleteAdminAmmoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdminAmmoRaw(requestParameters, initOverrides);
    }

    /**
     * Видалення підрозділу в адміністративному інтерфейсі
     * Видалення підрозділу
     */
    async deleteAdminDivisionRaw(requestParameters: DeleteAdminDivisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteAdminDivision().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/division/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Видалення підрозділу в адміністративному інтерфейсі
     * Видалення підрозділу
     */
    async deleteAdminDivision(requestParameters: DeleteAdminDivisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdminDivisionRaw(requestParameters, initOverrides);
    }

    /**
     * Видалення ОСУВ в адміністративному інтерфейсі
     * Видалення ОСУВ
     */
    async deleteAdminOSGRaw(requestParameters: DeleteAdminOSGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteAdminOSG().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/osg/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Видалення ОСУВ в адміністративному інтерфейсі
     * Видалення ОСУВ
     */
    async deleteAdminOSG(requestParameters: DeleteAdminOSGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdminOSGRaw(requestParameters, initOverrides);
    }

    /**
     * Видалення ОТУ в адміністративному інтерфейсі
     * Видалення ОТУ
     */
    async deleteAdminOTGRaw(requestParameters: DeleteAdminOTGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteAdminOTG().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/otg/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Видалення ОТУ в адміністративному інтерфейсі
     * Видалення ОТУ
     */
    async deleteAdminOTG(requestParameters: DeleteAdminOTGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdminOTGRaw(requestParameters, initOverrides);
    }

    /**
     * Видалення екіпажу в адміністративному інтерфейсі
     * Видалення екіпажу
     */
    async deleteAdminTeamRaw(requestParameters: DeleteAdminTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteAdminTeam().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/team/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Видалення екіпажу в адміністративному інтерфейсі
     * Видалення екіпажу
     */
    async deleteAdminTeam(requestParameters: DeleteAdminTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdminTeamRaw(requestParameters, initOverrides);
    }

    /**
     * Видалення користувача в адміністративному інтерфейсі
     * Видалення користувача
     */
    async deleteAdminUserRaw(requestParameters: DeleteAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteAdminUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Видалення користувача в адміністративному інтерфейсі
     * Видалення користувача
     */
    async deleteAdminUser(requestParameters: DeleteAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdminUserRaw(requestParameters, initOverrides);
    }

    /**
     * Видалення зони в адміністративному інтерфейсі
     * Видалення зони
     */
    async deleteAdminZoneRaw(requestParameters: DeleteAdminZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteAdminZone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/zone/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Видалення зони в адміністративному інтерфейсі
     * Видалення зони
     */
    async deleteAdminZone(requestParameters: DeleteAdminZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdminZoneRaw(requestParameters, initOverrides);
    }

    /**
     * Вилучення FAQ запису
     */
    async deleteFAQRaw(requestParameters: DeleteFAQRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteFAQ().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/faq/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Вилучення FAQ запису
     */
    async deleteFAQ(requestParameters: DeleteFAQRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.deleteFAQRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Видалення місії
     */
    async deleteMissionRaw(requestParameters: DeleteMissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteMission().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/missions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Видалення місії
     */
    async deleteMission(requestParameters: DeleteMissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.deleteMissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Видалення цілі
     */
    async deleteTargetRaw(requestParameters: DeleteTargetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteTarget().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/targets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Видалення цілі
     */
    async deleteTarget(requestParameters: DeleteTargetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.deleteTargetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Редагування результату місії
     */
    async editMissionResultRaw(requestParameters: EditMissionResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling editMissionResult().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling editMissionResult().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/missions/{id}/result`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputMissionResultToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Редагування результату місії
     */
    async editMissionResult(requestParameters: EditMissionResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.editMissionResultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Редагування відео для місії
     */
    async editMissionVideoRaw(requestParameters: EditMissionVideoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling editMissionVideo().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling editMissionVideo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/missions/{id}/video`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputMissionVideoToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Редагування відео для місії
     */
    async editMissionVideo(requestParameters: EditMissionVideoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editMissionVideoRaw(requestParameters, initOverrides);
    }

    /**
     * Редагування цілі
     */
    async editTargetHandlerRaw(requestParameters: EditTargetHandlerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling editTargetHandler().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling editTargetHandler().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/targets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputTargetToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Редагування цілі
     */
    async editTargetHandler(requestParameters: EditTargetHandlerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.editTargetHandlerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Завершення сесії бойового чергування, використовується для ручного дотермінового завершення сесії командиром
     * Завершення сесії
     */
    async finishSessionRaw(requestParameters: FinishSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling finishSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/session/finish-session/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Завершення сесії бойового чергування, використовується для ручного дотермінового завершення сесії командиром
     * Завершення сесії
     */
    async finishSession(requestParameters: FinishSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.finishSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання інформації про БК в адміністративному інтерфейсі
     * Отримання БК
     */
    async getAdminAmmoRaw(requestParameters: GetAdminAmmoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputAdminAmmo>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAdminAmmo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/ammo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputAdminAmmoFromJSON(jsonValue));
    }

    /**
     * Отримання інформації про БК в адміністративному інтерфейсі
     * Отримання БК
     */
    async getAdminAmmo(requestParameters: GetAdminAmmoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputAdminAmmo> {
        const response = await this.getAdminAmmoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку БК підрозділу в адміністративному інтерфейсі
     * Отримання списку БК
     */
    async getAdminAmmoListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAdminAmmo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/ammo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAdminAmmoFromJSON));
    }

    /**
     * Отримання списку БК підрозділу в адміністративному інтерфейсі
     * Отримання списку БК
     */
    async getAdminAmmoList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAdminAmmo>> {
        const response = await this.getAdminAmmoListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання інформації про підрозділ в адміністративному інтерфейсі
     * Отримання інформації про підрозділ
     */
    async getAdminDivisionRaw(requestParameters: GetAdminDivisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputAdminDivision>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAdminDivision().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/division/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputAdminDivisionFromJSON(jsonValue));
    }

    /**
     * Отримання інформації про підрозділ в адміністративному інтерфейсі
     * Отримання інформації про підрозділ
     */
    async getAdminDivision(requestParameters: GetAdminDivisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputAdminDivision> {
        const response = await this.getAdminDivisionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку доступних користувачу підрозділів в адміністративному інтерфейсі
     * Отримання доступних підрозділів
     */
    async getAdminDivisionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAdminDivision>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/divisions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAdminDivisionFromJSON));
    }

    /**
     * Отримання списку доступних користувачу підрозділів в адміністративному інтерфейсі
     * Отримання доступних підрозділів
     */
    async getAdminDivisions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAdminDivision>> {
        const response = await this.getAdminDivisionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання інформації про ОСУВ в адміністративному інтерфейсі
     * Отримання ОСУВ
     */
    async getAdminOSGRaw(requestParameters: GetAdminOSGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputAdminOSG>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAdminOSG().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/osg/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputAdminOSGFromJSON(jsonValue));
    }

    /**
     * Отримання інформації про ОСУВ в адміністративному інтерфейсі
     * Отримання ОСУВ
     */
    async getAdminOSG(requestParameters: GetAdminOSGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputAdminOSG> {
        const response = await this.getAdminOSGRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку доступних користувачу ОСУВ в адміністративному інтерфейсі
     * Отримання доступних ОСУВ
     */
    async getAdminOSGsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAdminOSG>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/osgs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAdminOSGFromJSON));
    }

    /**
     * Отримання списку доступних користувачу ОСУВ в адміністративному інтерфейсі
     * Отримання доступних ОСУВ
     */
    async getAdminOSGs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAdminOSG>> {
        const response = await this.getAdminOSGsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання інформації про ОТУ в адміністративному інтерфейсі
     * Отримання ОТУ
     */
    async getAdminOTGRaw(requestParameters: GetAdminOTGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputAdminOTG>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAdminOTG().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/otg/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputAdminOTGFromJSON(jsonValue));
    }

    /**
     * Отримання інформації про ОТУ в адміністративному інтерфейсі
     * Отримання ОТУ
     */
    async getAdminOTG(requestParameters: GetAdminOTGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputAdminOTG> {
        const response = await this.getAdminOTGRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку доступних користувачу ОТУ в адміністративному інтерфейсі
     * Отримання доступних ОТУ
     */
    async getAdminOTGsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAdminOTG>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/otgs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAdminOTGFromJSON));
    }

    /**
     * Отримання списку доступних користувачу ОТУ в адміністративному інтерфейсі
     * Отримання доступних ОТУ
     */
    async getAdminOTGs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAdminOTG>> {
        const response = await this.getAdminOTGsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання інформації про екіпаж в адміністративному інтерфейсі
     * Отримання інформації про екіпаж
     */
    async getAdminTeamRaw(requestParameters: GetAdminTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputAdminTeam>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAdminTeam().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/team/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputAdminTeamFromJSON(jsonValue));
    }

    /**
     * Отримання інформації про екіпаж в адміністративному інтерфейсі
     * Отримання інформації про екіпаж
     */
    async getAdminTeam(requestParameters: GetAdminTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputAdminTeam> {
        const response = await this.getAdminTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку доступних користувачу екіпажів в адміністративному інтерфейсі
     * Отримання доступних екіпажів
     */
    async getAdminTeamsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAdminTeam>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/teams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAdminTeamFromJSON));
    }

    /**
     * Отримання списку доступних користувачу екіпажів в адміністративному інтерфейсі
     * Отримання доступних екіпажів
     */
    async getAdminTeams(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAdminTeam>> {
        const response = await this.getAdminTeamsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку доступних зон для форми редагування екіпажів
     * Отримання доступних зон
     */
    async getAdminTeamsZonesForSelectorRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAdminTeamsZonesSelector>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/teams/zones-selector`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAdminTeamsZonesSelectorFromJSON));
    }

    /**
     * Отримання списку доступних зон для форми редагування екіпажів
     * Отримання доступних зон
     */
    async getAdminTeamsZonesForSelector(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAdminTeamsZonesSelector>> {
        const response = await this.getAdminTeamsZonesForSelectorRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання інформації про користувача в адміністративному інтерфейсі
     * Отримання інформації про користувача
     */
    async getAdminUserRaw(requestParameters: GetAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputAdminUser>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAdminUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputAdminUserFromJSON(jsonValue));
    }

    /**
     * Отримання інформації про користувача в адміністративному інтерфейсі
     * Отримання інформації про користувача
     */
    async getAdminUser(requestParameters: GetAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputAdminUser> {
        const response = await this.getAdminUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку доступних ОТУ для форми редагування користувача
     * Отримання ОТУ
     */
    async getAdminUserOtgsForSelectorRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAdminUsersOtgsSelector>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/users/selector-otgs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAdminUsersOtgsSelectorFromJSON));
    }

    /**
     * Отримання списку доступних ОТУ для форми редагування користувача
     * Отримання ОТУ
     */
    async getAdminUserOtgsForSelector(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAdminUsersOtgsSelector>> {
        const response = await this.getAdminUserOtgsForSelectorRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання ролей для керування користувачем в адміністративному інтерфейсі
     * Список ролей
     */
    async getAdminUserRolesForSelectorRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAdminRoleSelector>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/users/selector-roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAdminRoleSelectorFromJSON));
    }

    /**
     * Отримання ролей для керування користувачем в адміністративному інтерфейсі
     * Список ролей
     */
    async getAdminUserRolesForSelector(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAdminRoleSelector>> {
        const response = await this.getAdminUserRolesForSelectorRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку доступних зон для форми редагування користувача
     * Отримання зон
     */
    async getAdminUserZonesForSelectorRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAdminUsersZonesSelector>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/users/selector-zones`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAdminUsersZonesSelectorFromJSON));
    }

    /**
     * Отримання списку доступних зон для форми редагування користувача
     * Отримання зон
     */
    async getAdminUserZonesForSelector(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAdminUsersZonesSelector>> {
        const response = await this.getAdminUserZonesForSelectorRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку доступних користувачу користувачів в адміністративному інтерфейсі
     * Отримання доступних користувачів
     */
    async getAdminUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAdminUser>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAdminUserFromJSON));
    }

    /**
     * Отримання списку доступних користувачу користувачів в адміністративному інтерфейсі
     * Отримання доступних користувачів
     */
    async getAdminUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAdminUser>> {
        const response = await this.getAdminUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання інформації про зону в адміністративному інтерфейсі
     * Отримання зони
     */
    async getAdminZoneRaw(requestParameters: GetAdminZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputAdminZone>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAdminZone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/zone/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputAdminZoneFromJSON(jsonValue));
    }

    /**
     * Отримання інформації про зону в адміністративному інтерфейсі
     * Отримання зони
     */
    async getAdminZone(requestParameters: GetAdminZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputAdminZone> {
        const response = await this.getAdminZoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку доступних користувачу зон в адміністративному інтерфейсі
     * Отримання доступних зон
     */
    async getAdminZonesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAdminZone>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/zones`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAdminZoneFromJSON));
    }

    /**
     * Отримання списку доступних користувачу зон в адміністративному інтерфейсі
     * Отримання доступних зон
     */
    async getAdminZones(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAdminZone>> {
        const response = await this.getAdminZonesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку БК підрозділу в інтерфейсі історії
     * Отримання списку БК для місії
     */
    async getAmmoListForMissionRaw(requestParameters: GetAmmoListForMissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAmmo>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAmmoListForMission().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/missions/{id}/ammo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAmmoFromJSON));
    }

    /**
     * Отримання списку БК підрозділу в інтерфейсі історії
     * Отримання списку БК для місії
     */
    async getAmmoListForMission(requestParameters: GetAmmoListForMissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAmmo>> {
        const response = await this.getAmmoListForMissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку доступних привілегій, для перевірки доступності елементів в застосунку
     * Отримання привілегій
     */
    async getAuthPermissionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Отримання списку доступних привілегій, для перевірки доступності елементів в застосунку
     * Отримання привілегій
     */
    async getAuthPermissions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAuthPermissionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання сесійної інформації про залогіненого користувача
     * Перевірка стану аутентифікації
     */
    async getAuthSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputUserIdentity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/session`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputUserIdentityFromJSON(jsonValue));
    }

    /**
     * Отримання сесійної інформації про залогіненого користувача
     * Перевірка стану аутентифікації
     */
    async getAuthSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputUserIdentity> {
        const response = await this.getAuthSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Спеціальний хеш токену, який надає доступ до створення потоку на іншому домені, так як із за proxy SSE не працюютть
     * Отримання хешу токена
     */
    async getAuthStreamTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/stream_token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Спеціальний хеш токену, який надає доступ до створення потоку на іншому домені, так як із за proxy SSE не працюютть
     * Отримання хешу токена
     */
    async getAuthStreamToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getAuthStreamTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання токена доступу для авторизації в застосунку
     * Отримання токена доступу
     */
    async getAuthTokenRaw(requestParameters: GetAuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling getAuthToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputAuthTokenToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Отримання токена доступу для авторизації в застосунку
     * Отримання токена доступу
     */
    async getAuthToken(requestParameters: GetAuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getAuthTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання даних поточної місії
     * Активна місія
     */
    async getBookingDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputBookingData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/fly/booking-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputBookingDataFromJSON(jsonValue));
    }

    /**
     * Отримання даних поточної місії
     * Активна місія
     */
    async getBookingData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputBookingData> {
        const response = await this.getBookingDataRaw(initOverrides);
        return await response.value();
    }

    /**
     * Сітка частот
     */
    async getChannelsMapRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputChannelsMap>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/fly/channels-map`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputChannelsMapFromJSON(jsonValue));
    }

    /**
     * Сітка частот
     */
    async getChannelsMap(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputChannelsMap> {
        const response = await this.getChannelsMapRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання підрозділів ( RESTFul модель)
     */
    async getDivisionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputDivision>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/divisions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputDivisionFromJSON));
    }

    /**
     * Отримання підрозділів ( RESTFul модель)
     */
    async getDivisions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputDivision>> {
        const response = await this.getDivisionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання одного FAQ запису
     */
    async getFAQRaw(requestParameters: GetFAQRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputFaq>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getFAQ().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/faq/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputFaqFromJSON(jsonValue));
    }

    /**
     * Отримання одного FAQ запису
     */
    async getFAQ(requestParameters: GetFAQRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputFaq> {
        const response = await this.getFAQRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання усіх FAQ записів
     */
    async getFAQsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputFaq>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/faqs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputFaqFromJSON));
    }

    /**
     * Отримання усіх FAQ записів
     */
    async getFAQs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputFaq>> {
        const response = await this.getFAQsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Завершені місії
     */
    async getFlyHistoryRaw(requestParameters: GetFlyHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputHistoryResult>> {
        const queryParameters: any = {};

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['sortBy'] != null) {
            queryParameters['sort_by'] = requestParameters['sortBy'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['order_by'] = requestParameters['orderBy'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/missions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputHistoryResultFromJSON(jsonValue));
    }

    /**
     * Завершені місії
     */
    async getFlyHistory(requestParameters: GetFlyHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputHistoryResult> {
        const response = await this.getFlyHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання даних поточної місії
     * Активна місія
     */
    async getFlyMissionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputMissionData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/fly/mission`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputMissionDataFromJSON(jsonValue));
    }

    /**
     * Отримання даних поточної місії
     * Активна місія
     */
    async getFlyMission(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputMissionData> {
        const response = await this.getFlyMissionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання даних поточної сесії (бойового чергування) користувача
     * Активна сесія (бойове чергування)
     */
    async getFlySessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/fly/session`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отримання даних поточної сесії (бойового чергування) користувача
     * Активна сесія (бойове чергування)
     */
    async getFlySession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getFlySessionRaw(initOverrides);
    }

    /**
     * Активні місії зони
     */
    async getFlyZoneChannelDataRaw(requestParameters: GetFlyZoneChannelDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputZoneChannelData>> {
        if (requestParameters['zone'] == null) {
            throw new runtime.RequiredError(
                'zone',
                'Required parameter "zone" was null or undefined when calling getFlyZoneChannelData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/fly/zone-channel-data/{zone}`.replace(`{${"zone"}}`, encodeURIComponent(String(requestParameters['zone']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputZoneChannelDataFromJSON(jsonValue));
    }

    /**
     * Активні місії зони
     */
    async getFlyZoneChannelData(requestParameters: GetFlyZoneChannelDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputZoneChannelData> {
        const response = await this.getFlyZoneChannelDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання доступних зон на сторінці диспетчера польотів
     * Зони
     */
    async getFlyZonesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputZone>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/fly/zones`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputZoneFromJSON));
    }

    /**
     * Отримання доступних зон на сторінці диспетчера польотів
     * Зони
     */
    async getFlyZones(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputZone>> {
        const response = await this.getFlyZonesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Точка перевірки доступності API для відстеження працездатності застосунку
     * Перевірка доступності застосунку
     */
    async getHealthCheckRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/healthz`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Точка перевірки доступності API для відстеження працездатності застосунку
     * Перевірка доступності застосунку
     */
    async getHealthCheck(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getHealthCheckRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання історії завершених місій
     * Отримання даних для редагування місії
     */
    async getMissionEditableDataRaw(requestParameters: GetMissionEditableDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputHistoryEditableData>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getMissionEditableData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/missions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputHistoryEditableDataFromJSON(jsonValue));
    }

    /**
     * Отримання історії завершених місій
     * Отримання даних для редагування місії
     */
    async getMissionEditableData(requestParameters: GetMissionEditableDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputHistoryEditableData> {
        const response = await this.getMissionEditableDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Завантаження інформації по кількості користувачів онлайн
     */
    async getOnlineChartsDataRaw(requestParameters: GetOnlineChartsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAnalyticsOnlineUsers>>> {
        if (requestParameters['period'] == null) {
            throw new runtime.RequiredError(
                'period',
                'Required parameter "period" was null or undefined when calling getOnlineChartsData().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['period'] != null) {
            queryParameters['period'] = requestParameters['period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/online-charts-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAnalyticsOnlineUsersFromJSON));
    }

    /**
     * Завантаження інформації по кількості користувачів онлайн
     */
    async getOnlineChartsData(requestParameters: GetOnlineChartsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAnalyticsOnlineUsers>> {
        const response = await this.getOnlineChartsDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання сесій БЧ ( RESTFul модель)
     */
    async getSessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputSession>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputSessionFromJSON));
    }

    /**
     * Отримання сесій БЧ ( RESTFul модель)
     */
    async getSessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputSession>> {
        const response = await this.getSessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку екіпажів з додатковою інформацією про попередні та активні політні сесії
     * Отримання екіпажів для сесій
     */
    async getSessionsDataRaw(requestParameters: GetSessionsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputSessionsData>> {
        const queryParameters: any = {};

        if (requestParameters['division'] != null) {
            queryParameters['division'] = requestParameters['division'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sessions/data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputSessionsDataFromJSON(jsonValue));
    }

    /**
     * Отримання списку екіпажів з додатковою інформацією про попередні та активні політні сесії
     * Отримання екіпажів для сесій
     */
    async getSessionsData(requestParameters: GetSessionsDataRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputSessionsData> {
        const response = await this.getSessionsDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку пілотів, які можуть бути назначені в інтерфейсі затвердження політних сесій
     * Отримання пілотів
     */
    async getSessionsPilotsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sessions/pilots`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отримання списку пілотів, які можуть бути назначені в інтерфейсі затвердження політних сесій
     * Отримання пілотів
     */
    async getSessionsPilots(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getSessionsPilotsRaw(initOverrides);
    }

    /**
     * Отримання даних для редагування цілі
     */
    async getTargetDataRaw(requestParameters: GetTargetDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputTargetData>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getTargetData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/targets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputTargetDataFromJSON(jsonValue));
    }

    /**
     * Отримання даних для редагування цілі
     */
    async getTargetData(requestParameters: GetTargetDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputTargetData> {
        const response = await this.getTargetDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку цілей підрозділу в інтерфейсі історії
     * Отримання списку цілей для місії
     */
    async getTargetListForMissionRaw(requestParameters: GetTargetListForMissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputTargetSelectData>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getTargetListForMission().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/missions/{id}/targets`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputTargetSelectDataFromJSON));
    }

    /**
     * Отримання списку цілей підрозділу в інтерфейсі історії
     * Отримання списку цілей для місії
     */
    async getTargetListForMission(requestParameters: GetTargetListForMissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputTargetSelectData>> {
        const response = await this.getTargetListForMissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання типів цілей
     */
    async getTargetTypesListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputTargetTypesResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/target-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputTargetTypesResultFromJSON(jsonValue));
    }

    /**
     * Отримання типів цілей
     */
    async getTargetTypesList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputTargetTypesResult> {
        const response = await this.getTargetTypesListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку цілей підрозділу
     */
    async getTargetsListRaw(requestParameters: GetTargetsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputTargetsResult>> {
        const queryParameters: any = {};

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['sortBy'] != null) {
            queryParameters['sort_by'] = requestParameters['sortBy'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['order_by'] = requestParameters['orderBy'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/targets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputTargetsResultFromJSON(jsonValue));
    }

    /**
     * Отримання списку цілей підрозділу
     */
    async getTargetsList(requestParameters: GetTargetsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputTargetsResult> {
        const response = await this.getTargetsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Завантаження інформації по топовим підрозділам
     */
    async getTopDivisionsChartsDataHandlerRaw(requestParameters: GetTopDivisionsChartsDataHandlerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputAnalyticsTopDivisions>>> {
        if (requestParameters['period'] == null) {
            throw new runtime.RequiredError(
                'period',
                'Required parameter "period" was null or undefined when calling getTopDivisionsChartsDataHandler().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['period'] != null) {
            queryParameters['period'] = requestParameters['period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/top-divisions-charts-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputAnalyticsTopDivisionsFromJSON));
    }

    /**
     * Завантаження інформації по топовим підрозділам
     */
    async getTopDivisionsChartsDataHandler(requestParameters: GetTopDivisionsChartsDataHandlerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputAnalyticsTopDivisions>> {
        const response = await this.getTopDivisionsChartsDataHandlerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання профілю користувача
     */
    async getUserProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputUserProfile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputUserProfileFromJSON(jsonValue));
    }

    /**
     * Отримання профілю користувача
     */
    async getUserProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputUserProfile> {
        const response = await this.getUserProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання останніх політних даних, таких як пілот, смуга, канал, для передзаповнення форми
     * Остані політні дані для передзаповнення форми
     */
    async getUsersLastFlightDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputLastFlightData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/fly/last-mission-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputLastFlightDataFromJSON(jsonValue));
    }

    /**
     * Отримання останніх політних даних, таких як пілот, смуга, канал, для передзаповнення форми
     * Остані політні дані для передзаповнення форми
     */
    async getUsersLastFlightData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputLastFlightData> {
        const response = await this.getUsersLastFlightDataRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання прапорців статуса навчання для командир
     * Статус навчання
     */
    async getWalkthroughStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputWalkthroughStatus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/walkthrough/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputWalkthroughStatusFromJSON(jsonValue));
    }

    /**
     * Отримання прапорців статуса навчання для командир
     * Статус навчання
     */
    async getWalkthroughStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputWalkthroughStatus> {
        const response = await this.getWalkthroughStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання всіх підрозділів для координатора зони
     * Список підрозділів
     */
    async getZoneAdminDivisionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputZoneAdminDivision>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/zone-admin/divisions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputZoneAdminDivisionFromJSON));
    }

    /**
     * Отримання всіх підрозділів для координатора зони
     * Список підрозділів
     */
    async getZoneAdminDivisions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputZoneAdminDivision>> {
        const response = await this.getZoneAdminDivisionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку зон для координатора ОТУ
     */
    async getZoneAdminOtgZonesListRaw(requestParameters: GetZoneAdminOtgZonesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputZoneRequestsZonesSelector>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getZoneAdminOtgZonesList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/zone-admin/zones-list/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputZoneRequestsZonesSelectorFromJSON));
    }

    /**
     * Отримання списку зон для координатора ОТУ
     */
    async getZoneAdminOtgZonesList(requestParameters: GetZoneAdminOtgZonesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputZoneRequestsZonesSelector>> {
        const response = await this.getZoneAdminOtgZonesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання інформації про заявку в інтерфейсі координатора зони
     * Отримання інформації про заявки
     */
    async getZoneAdminRequestRaw(requestParameters: GetZoneAdminRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputZoneAdminRequest>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getZoneAdminRequest().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/zone-admin/requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputZoneAdminRequestFromJSON(jsonValue));
    }

    /**
     * Отримання інформації про заявку в інтерфейсі координатора зони
     * Отримання інформації про заявки
     */
    async getZoneAdminRequest(requestParameters: GetZoneAdminRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputZoneAdminRequest> {
        const response = await this.getZoneAdminRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку активних заявок на доєднання до зони
     * Список заявок на доєднання до зони
     */
    async getZoneAdminRequestsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputZoneAdminRequest>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/zone-admin/requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputZoneAdminRequestFromJSON));
    }

    /**
     * Отримання списку активних заявок на доєднання до зони
     * Список заявок на доєднання до зони
     */
    async getZoneAdminRequests(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputZoneAdminRequest>> {
        const response = await this.getZoneAdminRequestsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Керування запитами на приєднання до зони (командир)
     */
    async getZoneRequestsListRaw(requestParameters: GetZoneRequestsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputZoneRequestsResult>> {
        const queryParameters: any = {};

        if (requestParameters['sortBy'] != null) {
            queryParameters['sort_by'] = requestParameters['sortBy'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['order_by'] = requestParameters['orderBy'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/division/zone-requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputZoneRequestsResultFromJSON(jsonValue));
    }

    /**
     * Керування запитами на приєднання до зони (командир)
     */
    async getZoneRequestsList(requestParameters: GetZoneRequestsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputZoneRequestsResult> {
        const response = await this.getZoneRequestsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отримання списку зон для форми запита
     */
    async getZoneRequestsOtgListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OutputZoneRequestsOtgsSelector>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/division/zone-requests/otg-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutputZoneRequestsOtgsSelectorFromJSON));
    }

    /**
     * Отримання списку зон для форми запита
     */
    async getZoneRequestsOtgList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OutputZoneRequestsOtgsSelector>> {
        const response = await this.getZoneRequestsOtgListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Завершення робочої сесії, з подальшим видаленням токен-куки доступу
     * Вихід з системи
     */
    async logoutAuthRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Завершення робочої сесії, з подальшим видаленням токен-куки доступу
     * Вихід з системи
     */
    async logoutAuth(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.logoutAuthRaw(initOverrides);
        return await response.value();
    }

    /**
     * Збереження параметрів девайса користувача
     */
    async putBrowserInfoRaw(requestParameters: PutBrowserInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling putBrowserInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/analytics/browser-info`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputBrowsersInfoToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Збереження параметрів девайса користувача
     */
    async putBrowserInfo(requestParameters: PutBrowserInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putBrowserInfoRaw(requestParameters, initOverrides);
    }

    /**
     * Старт місії для екіпажу поточного користувача
     * Старт місії
     */
    async startFlySessionRaw(requestParameters: StartFlySessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling startFlySession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/fly/mission`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputStartSessionToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Старт місії для екіпажу поточного користувача
     * Старт місії
     */
    async startFlySession(requestParameters: StartFlySessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.startFlySessionRaw(requestParameters, initOverrides);
    }

    /**
     * Ручне завершення місії для екіпажу поточного користувача
     * Ручне завершення місії
     */
    async stopFlySessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/fly/mission`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Ручне завершення місії для екіпажу поточного користувача
     * Ручне завершення місії
     */
    async stopFlySession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.stopFlySessionRaw(initOverrides);
    }

    /**
     * Оновлення БК в адміністративному інтерфейсі
     * Оновлення БК
     */
    async updateAdminAmmoRaw(requestParameters: UpdateAdminAmmoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAdminAmmo().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling updateAdminAmmo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/ammo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminAmmoToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Оновлення БК в адміністративному інтерфейсі
     * Оновлення БК
     */
    async updateAdminAmmo(requestParameters: UpdateAdminAmmoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.updateAdminAmmoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Оновлення ОСУВ в адміністративному інтерфейсі
     * Оновлення ОСУВ
     */
    async updateAdminOSGRaw(requestParameters: UpdateAdminOSGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAdminOSG().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling updateAdminOSG().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/osg/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminOSGToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Оновлення ОСУВ в адміністративному інтерфейсі
     * Оновлення ОСУВ
     */
    async updateAdminOSG(requestParameters: UpdateAdminOSGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.updateAdminOSGRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Оновлення ОТУ в адміністративному інтерфейсі
     * Оновлення ОТУ
     */
    async updateAdminOTGRaw(requestParameters: UpdateAdminOTGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAdminOTG().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling updateAdminOTG().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/otg/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminOTGToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Оновлення ОТУ в адміністративному інтерфейсі
     * Оновлення ОТУ
     */
    async updateAdminOTG(requestParameters: UpdateAdminOTGRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.updateAdminOTGRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Оновлення екіпажу в адміністративному інтерфейсі
     * Оновлення екіпажу
     */
    async updateAdminTeamRaw(requestParameters: UpdateAdminTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAdminTeam().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling updateAdminTeam().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/team/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminTeamToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Оновлення екіпажу в адміністративному інтерфейсі
     * Оновлення екіпажу
     */
    async updateAdminTeam(requestParameters: UpdateAdminTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.updateAdminTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Оновлення користувача в адміністративному інтерфейсі
     * Оновлення користувача
     */
    async updateAdminUserRaw(requestParameters: UpdateAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAdminUser().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling updateAdminUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputEditAdminUserToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Оновлення користувача в адміністративному інтерфейсі
     * Оновлення користувача
     */
    async updateAdminUser(requestParameters: UpdateAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.updateAdminUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Оновлення зони в адміністративному інтерфейсі
     * Оновлення зони
     */
    async updateAdminZoneRaw(requestParameters: UpdateAdminZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAdminZone().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling updateAdminZone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/zone/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminZoneToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Оновлення зони в адміністративному інтерфейсі
     * Оновлення зони
     */
    async updateAdminZone(requestParameters: UpdateAdminZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.updateAdminZoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Редагування FAQ запису
     */
    async updateFAQRaw(requestParameters: UpdateFAQRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateFAQ().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling updateFAQ().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/faq/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputFAQToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Редагування FAQ запису
     */
    async updateFAQ(requestParameters: UpdateFAQRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.updateFAQRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Оновлення профілю користувача самим користувачем
     * Оновлення профілю користувача
     */
    async updateUserProfileRaw(requestParameters: UpdateUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling updateUserProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputUserProfileToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Оновлення профілю користувача самим користувачем
     * Оновлення профілю користувача
     */
    async updateUserProfile(requestParameters: UpdateUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserProfileRaw(requestParameters, initOverrides);
    }

    /**
     * Оновлення підрозділу в адміністративному інтерфейсі
     * Оновлення підрозділу
     */
    async updatedAdminDivisionRaw(requestParameters: UpdatedAdminDivisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatedAdminDivision().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling updatedAdminDivision().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/division/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputAdminDivisionToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Оновлення підрозділу в адміністративному інтерфейсі
     * Оновлення підрозділу
     */
    async updatedAdminDivision(requestParameters: UpdatedAdminDivisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.updatedAdminDivisionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Додавання підрозділу до зони, за яку відповідає координатор
     * Додавання підрозділу до зони
     */
    async zoneAdminApproveRequestRaw(requestParameters: ZoneAdminApproveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling zoneAdminApproveRequest().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling zoneAdminApproveRequest().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/zone-admin/requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputZoneAdminZoneToJSON(requestParameters['request']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Додавання підрозділу до зони, за яку відповідає координатор
     * Додавання підрозділу до зони
     */
    async zoneAdminApproveRequest(requestParameters: ZoneAdminApproveRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.zoneAdminApproveRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Відхилення заявки на приєдання до зони
     * Відхилення заявки на приєдання до зони
     */
    async zoneAdminDeclineRequestRaw(requestParameters: ZoneAdminDeclineRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling zoneAdminDeclineRequest().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/zone-admin/requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Відхилення заявки на приєдання до зони
     * Відхилення заявки на приєдання до зони
     */
    async zoneAdminDeclineRequest(requestParameters: ZoneAdminDeclineRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.zoneAdminDeclineRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Видалення підрозділу з зони, за яку відповідає координатор
     * Видалення підрозділу з зони
     */
    async zoneAdminDeleteDivisionFromZoneRaw(requestParameters: ZoneAdminDeleteDivisionFromZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['divisionId'] == null) {
            throw new runtime.RequiredError(
                'divisionId',
                'Required parameter "divisionId" was null or undefined when calling zoneAdminDeleteDivisionFromZone().'
            );
        }

        if (requestParameters['zoneId'] == null) {
            throw new runtime.RequiredError(
                'zoneId',
                'Required parameter "zoneId" was null or undefined when calling zoneAdminDeleteDivisionFromZone().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/zone-admin/division/{divisionId}/zone/{zoneId}`.replace(`{${"divisionId"}}`, encodeURIComponent(String(requestParameters['divisionId']))).replace(`{${"zoneId"}}`, encodeURIComponent(String(requestParameters['zoneId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Видалення підрозділу з зони, за яку відповідає координатор
     * Видалення підрозділу з зони
     */
    async zoneAdminDeleteDivisionFromZone(requestParameters: ZoneAdminDeleteDivisionFromZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.zoneAdminDeleteDivisionFromZoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetFlyHistoryOrderByEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetFlyHistoryOrderByEnum = typeof GetFlyHistoryOrderByEnum[keyof typeof GetFlyHistoryOrderByEnum];
/**
 * @export
 */
export const GetOnlineChartsDataPeriodEnum = {
    LastHour: 'lastHour',
    Last12Hours: 'last12Hours',
    Last24Hours: 'last24Hours',
    LastWeek: 'lastWeek',
    LastMonth: 'lastMonth'
} as const;
export type GetOnlineChartsDataPeriodEnum = typeof GetOnlineChartsDataPeriodEnum[keyof typeof GetOnlineChartsDataPeriodEnum];
/**
 * @export
 */
export const GetTargetsListOrderByEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetTargetsListOrderByEnum = typeof GetTargetsListOrderByEnum[keyof typeof GetTargetsListOrderByEnum];
/**
 * @export
 */
export const GetTopDivisionsChartsDataHandlerPeriodEnum = {
    Last24Hours: 'last24Hours',
    LastWeek: 'lastWeek',
    LastMonth: 'lastMonth'
} as const;
export type GetTopDivisionsChartsDataHandlerPeriodEnum = typeof GetTopDivisionsChartsDataHandlerPeriodEnum[keyof typeof GetTopDivisionsChartsDataHandlerPeriodEnum];
/**
 * @export
 */
export const GetZoneRequestsListOrderByEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetZoneRequestsListOrderByEnum = typeof GetZoneRequestsListOrderByEnum[keyof typeof GetZoneRequestsListOrderByEnum];
