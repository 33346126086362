export const useNotifier = () => {
    const {isAuthenticated} = useAuth()
    const {public: config} = useRuntimeConfig()
    const domain = config.sseStreamDomain || ''
    const lastEventData: Ref<{}> = useState("lastServerEventData", () => ({}))
    const streamBasePath = domain + '/api/stream'
    const streamUrl = ref('')

    const {data, close, open} = useEventSource(streamUrl, [], {
        autoReconnect: {
            retries: 100,
            delay: 1000,
            onFailed() {
                alert('Failed to connect EventSource after 3 retries')
            },
        },
        immediate: false
    })

    callOnce(() => {
        watch(isAuthenticated, () => {
            if (isAuthenticated.value) {
                $fetch('/api/auth/stream_token')
                    .then(response => {
                        streamUrl.value = streamBasePath + '?stream_token=' + response
                        open()
                    })
            } else {
                close()
                lastEventData.value = {}
            }
        })

        watch(data, (newData) => {
            try {
                lastEventData.value = JSON.parse(newData as string)
            } catch (e) {
            }
        })
    })

    return {
        lastEventData,
    }
}