/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputTeamSessionInfo } from './OutputTeamSessionInfo';
import {
    OutputTeamSessionInfoFromJSON,
    OutputTeamSessionInfoFromJSONTyped,
    OutputTeamSessionInfoToJSON,
} from './OutputTeamSessionInfo';

/**
 * 
 * @export
 * @interface OutputTeamsSession
 */
export interface OutputTeamsSession {
    /**
     * 
     * @type {OutputTeamSessionInfo}
     * @memberof OutputTeamsSession
     */
    session?: OutputTeamSessionInfo;
    /**
     * 
     * @type {number}
     * @memberof OutputTeamsSession
     */
    teamId: number;
    /**
     * 
     * @type {string}
     * @memberof OutputTeamsSession
     */
    teamName: string;
    /**
     * 
     * @type {string}
     * @memberof OutputTeamsSession
     */
    zoneName: string;
}

/**
 * Check if a given object implements the OutputTeamsSession interface.
 */
export function instanceOfOutputTeamsSession(value: object): boolean {
    if (!('teamId' in value)) return false;
    if (!('teamName' in value)) return false;
    if (!('zoneName' in value)) return false;
    return true;
}

export function OutputTeamsSessionFromJSON(json: any): OutputTeamsSession {
    return OutputTeamsSessionFromJSONTyped(json, false);
}

export function OutputTeamsSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputTeamsSession {
    if (json == null) {
        return json;
    }
    return {
        
        'session': json['session'] == null ? undefined : OutputTeamSessionInfoFromJSON(json['session']),
        'teamId': json['teamId'],
        'teamName': json['teamName'],
        'zoneName': json['zoneName'],
    };
}

export function OutputTeamsSessionToJSON(value?: OutputTeamsSession | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'session': OutputTeamSessionInfoToJSON(value['session']),
        'teamId': value['teamId'],
        'teamName': value['teamName'],
        'zoneName': value['zoneName'],
    };
}

