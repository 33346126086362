/* tslint:disable */
/* eslint-disable */
/**
 * Контроль місій для FPV
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputPilotData } from './OutputPilotData';
import {
    OutputPilotDataFromJSON,
    OutputPilotDataFromJSONTyped,
    OutputPilotDataToJSON,
} from './OutputPilotData';

/**
 * 
 * @export
 * @interface OutputSessionData
 */
export interface OutputSessionData {
    /**
     * 
     * @type {boolean}
     * @memberof OutputSessionData
     */
    allowExtraChannel: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputSessionData
     */
    freqRange: string;
    /**
     * 
     * @type {number}
     * @memberof OutputSessionData
     */
    id: number;
    /**
     * 
     * @type {Array<OutputPilotData>}
     * @memberof OutputSessionData
     */
    pilots: Array<OutputPilotData>;
    /**
     * 
     * @type {number}
     * @memberof OutputSessionData
     */
    team: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OutputSessionData
     */
    zones: { [key: string]: string; };
}

/**
 * Check if a given object implements the OutputSessionData interface.
 */
export function instanceOfOutputSessionData(value: object): boolean {
    if (!('allowExtraChannel' in value)) return false;
    if (!('freqRange' in value)) return false;
    if (!('id' in value)) return false;
    if (!('pilots' in value)) return false;
    if (!('team' in value)) return false;
    if (!('zones' in value)) return false;
    return true;
}

export function OutputSessionDataFromJSON(json: any): OutputSessionData {
    return OutputSessionDataFromJSONTyped(json, false);
}

export function OutputSessionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputSessionData {
    if (json == null) {
        return json;
    }
    return {
        
        'allowExtraChannel': json['allowExtraChannel'],
        'freqRange': json['freqRange'],
        'id': json['id'],
        'pilots': ((json['pilots'] as Array<any>).map(OutputPilotDataFromJSON)),
        'team': json['team'],
        'zones': json['zones'],
    };
}

export function OutputSessionDataToJSON(value?: OutputSessionData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allowExtraChannel': value['allowExtraChannel'],
        'freqRange': value['freqRange'],
        'id': value['id'],
        'pilots': ((value['pilots'] as Array<any>).map(OutputPilotDataToJSON)),
        'team': value['team'],
        'zones': value['zones'],
    };
}

